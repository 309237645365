import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { Common } from 'src/app/common';
import { ImagePreviewComponent } from 'src/app/modules/modals/image-preview/image-preview.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { FileService } from 'src/app/shared/services/file.service';
import { MasterService } from 'src/app/shared/services/master.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { environment } from 'src/environments/environment';
import { AyushDoctorRegistrationComponent } from '../../ayush/ayush-doctor-registration/ayush-doctor-registration.component';
declare const updateStatus: any;
@Component({
  selector: 'app-doctor-registration',
  templateUrl: './doctor-registration.component.html',
  styleUrls: ['./doctor-registration.component.css'],
  providers: [DatePipe]
})

export class DoctorRegistrationComponent implements OnInit {

  @Input() title: string;
  @Input() doctor: any;
  @Input() isSubmit_Disabled: boolean = false;
  @Input() isEditable: boolean = false;
  isMeridian: boolean = false;
  isGNM = environment.isGNM; isSAGE = environment.isSAGE; isNIG = environment.isNIG;
  doctorForm: FormGroup;
  submitted = false;
  max_date = new Date(new Date().setFullYear(+`${new Date().getFullYear() - 21}`));
  isEdit = false;
  maxdate: Date;
  locationTypeList: Array<any> = [];
  stateList: Array<any> = [];
  districtList: Array<any> = [];
  mandalList: Array<any> = [];
  cityList: Array<any> = [];
  villageList: Array<any> = [];
  newSpecializationList: Array<any> = [];
  weekDaysList: Array<any> = [];
  dropdownSettings_weekDays = {};
  selectedItems = [];

  languagesList: Array<any> = [];
  dropdownSettings_language = {};
  selectedItems_language = [];
  selectedItems_module = [];

  super_SpecialisationList = [];
  is_AssistantDetails: boolean = false;

  uploadFileList: Array<{ id: number, reg_id: number, name: string, File: any, extensions: string, file_name: string, fileType: string, document_path: string }> = []
  labList: Array<any> = [];
  hospitalList: Array<any> = [];
  pharmacyList: Array<any> = [];
  genderList: Array<any> = [];
  maritalList: Array<any> = [];
  titleList: any;
  visitStatusList: any;
  addressTypesList: any;
  qualificationsList: Array<any> = [];
  specializationList: any;
  superSpecialityDegreeList: any;
  bankNamesList: any;
  departmentList: any = [];
  TENANT_ID: string = '';
  hide = 0; centerList = []
  refType: any = 'Center';
  isShwDS = false; isShwHQ = false; isShwPic = false; homeAddrs = ''; clncAddrs = ''; hsptlAddrs = '';
  mobPtrn = ''; mobErrMsg = ''; maxLngth = ''; doctorlist: any[] = []; dropdownSettings_module = {};
  lstHrs: Array<any> = [{ name: '00' }, { name: '01' }, { name: '02' }, { name: '03' }, { name: '04' }, { name: '05' }, { name: '06' }, { name: '07' }, { name: '08' }, { name: '09' }, { name: '10' }, { name: '11' }, { name: '12' }, { name: '13' }, { name: '14' }, { name: '15' }, { name: '16' }, { name: '17' }, { name: '18' }, { name: '19' }, { name: '20' }, { name: '21' }, { name: '22' }, { name: '23' }];
  lstMin: Array<any> = [{ name: '00' }, { name: '01' }, { name: '02' }, { name: '03' }, { name: '04' }, { name: '05' }, { name: '06' }, { name: '07' }, { name: '08' }, { name: '09' }, { name: '10' }, { name: '11' }, { name: '12' }, { name: '13' }, { name: '14' }, { name: '15' }, { name: '16' }, { name: '17' }, { name: '18' }, { name: '19' }, { name: '20' },
  { name: '21' }, { name: '22' }, { name: '23' }, { name: '24' }, { name: '25' }, { name: '26' }, { name: '27' }, { name: '28' }, { name: '29' }, { name: '30' }, { name: '31' }, { name: '32' }, { name: '33' }, { name: '34' }, { name: '35' }, { name: '36' }, { name: '37' }, { name: '38' }, { name: '39' }, { name: '40' },
  { name: '41' }, { name: '42' }, { name: '43' }, { name: '44' }, { name: '45' }, { name: '46' }, { name: '47' }, { name: '48' }, { name: '49' }, { name: '50' }, { name: '51' }, { name: '52' }, { name: '53' }, { name: '54' }, { name: '55' }, { name: '56' }, { name: '57' }, { name: '58' }, { name: '59' }];
  hubsList: any = [];
  getallhospitalTypeList: any;
  centerHospitals: any[];
  getallRefhospitalTypesList: any;
  referralHospitals: any[];
  centerType: any = '';
  hospitalId: any;
  hospitalName: any;
  selected_hospital: any = 'select';
  showCharge = true;
  hwList: Array<any> = [];
  constructor(public activeModal: NgbActiveModal
    , private modalService: NgbModal
    , public changeDetection: ChangeDetectorRef
    , private toastr: ToastrService
    , private datePipe: DatePipe
    , private formBuilder: FormBuilder
    , private fileService: FileService
    , private registrationService: RegistrationService
    , private authServ: AuthenticationService
    , private registrationsrv: RegistrationService
    , private notificationService: NotificationService,
    private ref: ChangeDetectorRef
    , private masterService: MasterService) {
    this.TENANT_ID = sessionStorage.getItem('currentTenant')
  }

  ngOnInit(): void {
    this.authServ.mobPtrnSubject.subscribe(s => this.mobPtrn = s), this.authServ.mobErrMsgSubject.subscribe(s => this.mobErrMsg = s),
      this.authServ.maxLngthSubject.subscribe(s => this.maxLngth = s), this.defaultLocations(), this.initFormControls(),
      this.setLocationTypeValidators(), this.setAssistantDetailsValidators(), this.locationChanged('', 'state'),
      //  this.locationChanged('36', 'district')//QA Pointing
     this.locationChanged('5', 'district');
    Common.setFocus('doctor_type_id'),
      this.getAllLabPharmacyHospital(); this.getAllDoctorModules();
    // console.log('URL CHECK', sessionStorage.getItem('tenantUrl'));
    if (this.refType === 'Center') {
      this.centerType = 'Center';
    }
    if (this.isNIG)
      this.doctorForm.get('aadhar_number').setValidators(null);
    this.doctorForm.get('aadhar_number').updateValueAndValidity();
    this.getallcentersGrid();
  }

  defaultLocations() {
    ['DigitalSignature', 'HighestQualifiedCertificate', 'BioData', 'Photo', 'audioRecord', 'AadharCard', 'PANCard'].forEach((element, ind) => this.uploadFileList.push({ id: 0, reg_id: ind + 1, name: element, File: null, extensions: '', file_name: '', fileType: ['8', '9', '4', '5', '10', '12', '13'][ind], document_path: '' }));
    ['png', 'jpg,jpeg,pdf', 'dot,dotx,doc,docx,pdf', 'jpg,jpeg,bmp', 'mp4', 'jpg,jpeg,png,pdf', 'jpg,jpeg,png,pdf'].forEach((item, i) => this.uploadFileList[i].extensions = item);

    this.dropdownSettings_language = {
      singleSelection: false,
      idField: 'id',
      textField: 'language_name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettings_module = {
      singleSelection: true,
      idField: 'id',
      textField: 'module_name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    this.dropdownSettings_weekDays = {
      singleSelection: false,
      idField: 'id',
      textField: 'weekday',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  initFormControls() {
    this.doctorForm = this.formBuilder.group({
      id: ['0'],
      user_id: [''],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      birth_date: ['', Validators.required],
      gender_id: ['', Validators.required],
      mobile: ['', [Validators.required, Validators.minLength(+this.maxLngth), Validators.pattern(this.mobPtrn)]],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      mci_number: ['', Validators.required],
      mci_number_state_id: ['1', Validators.required],
      department_id: [''],
      marital_status_id: ['1', Validators.required],
      qualification_id: ['', Validators.required],
      specialization_id: ['', Validators.required],
      years_experience: ['', Validators.required],
      from_time: [new Date()],
      to_time: [new Date()],
      // doctor_available_slots: this.formBuilder.group({}),
      doctor_languages: ['', Validators.required],
      doctor_address: [''],
      doctor_hospitals: [''],
      address: [''],
      age: [''],
      exclusive_patients: [true],
      pin_code: ['', [Validators.required, this.isSAGE ? Validators.pattern('^([1-9]){1,6}$') : Validators.pattern('^([1-9]){1}([0-9]){5}?$')]],
      weekday_id: [],
      doctor_type_id: ['', Validators.required],
      audio_consultation_price: [0, Validators.required],
      video_consultation_price: [0, Validators.required],
      hospital_name: [null],
      //  state_id: [36, Validators.required],//QA Pointing
     state_id: ['', Validators.required],//Training
      location_type_id: ['', Validators.required],
      district_id: ['', Validators.required],
      mandal_id: [''],
      village_id: [''],
      city_id: [''],
      home_address: [''],
      clinic_address: [''],
      hospital_address: [''],
      hospital_working_in_presently: [''],
      other_hospital: [''],
      super_qualification_id: [''],
      super_specialization_id: [''],
      max_consultation_time: [''],
      account_activated: [true],
      send_notification_to_mobile: [false],
      bank_account_number: [12345678910],
      branch_name: [1],
      branch_location: ['cba'],
      ifsc_code: ['SBIN0001050',],
      pharmacy_id: [''],
      lab_id: [''],
      longitude: [56.365],
      latitude: [56.362],
      assistant_detail: [false],
      assistant_first_name: [''],
      assistant_contact_number: [''],
      assistant_email: [''],
      notification_to_assistant: [false],
      aadhar_number: ['', Validators.pattern('[0-9]{12}$')],
      prescription_address: [''],
      reg_expiry_date: ['', Validators.required],
      login_name: [''],
      password: [''],
      assistant_last_name: "",
      center_id: [''],
      digitalFile: ['', Validators.required],
      qualificationFile: ['', Validators.required],
      photo: ['', Validators.required],
      group_reference_code: [''],
      individual_reference_code: [''],
      exclusive_reference_code: [false],
      aadharFile: [''],// Validators.required
      panFile: [''],//, Validators.required],
      other_qualification: ['', Validators.maxLength(100)],
      employee_id: [''],
      doctor_modules: [[]],
      docTmplt: [''],

      start_hours: [''],
      start_minutes: [''],
      end_hours: [''],
      end_minutes: [''],
      center_type: [this.refType],
      hospital_type_id: [null],
      referral_hospital: [null],
      isHW: [false],
      hlthWorker: [''],
      charge_amt: [0],
    });
  }

  getAllDoctorModules() {
    this.registrationsrv.getAllDctrMdule().subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info(res.message, 'Doctor List');
        else
          this.doctorlist = res.data;
      }
    })
  }

  get f() { return this.doctorForm.controls; }

  onSubmit(event: any) {
    let lgnName: string = this.doctorForm.get('id').value == '0' ? this.doctorForm.value.email : this.doctor?.user_registration?.login_name ?? '',
      isEdit: boolean = false;
    this.doctorForm.value.bank_account_number = '12345678910';
    this.doctorForm.value.branch_name = '1';
    this.doctorForm.value.branch_location = 'ukt';
    this.doctorForm.value.hospital_name = this.hospitalName;
    if (this.centerType === 'Hub') {
      this.doctorForm.get('center_type').setValue('Hub');
    }

    this.doctorForm.value.ifsc_code = 'SBIN0001050';
    event.preventDefault(), this.submitted = true, 
    Common.getFormValidationErrors(this.doctorForm);
    if (this.doctorForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    else if (this.doctorForm.value.isHW == true && this.doctorForm.value.hlthWorker == '') {
      this.toastr.warning('Please select Health Worker');
      return;
    }
    else if ((this.doctorForm.value.home_address || '') == '' && (this.doctorForm.value.clinic_address || '') == '' && (this.doctorForm.value.hospital_address || '') == '') {
      this.toastr.warning('Please provide one of the address');
      return;
    }
    else {
      let user = this.authServ.currentUserValue, roleID = +sessionStorage.getItem('logUsrRoleId'), crntTentId = +sessionStorage.getItem('currentTenant');
      this.doctorForm.patchValue({
        login_name: lgnName == '' ? this.doctorForm.get('email').value : lgnName,
        password: +this.doctorForm.value.id == 0 ? '123456' : this.doctor ? this.doctor.user_registration?.password ?? '123456' : ''
      });
      const sendData = JSON.parse(JSON.stringify(this.doctorForm.getRawValue()));

      if (+sendData.audio_consultation_price > +sendData.video_consultation_price) {
        this.toastr.info('Video rate should greater than Audio rate')
        return;
      }

      Object.assign(sendData, {
        "alternate_mobile_no": user?.alternate_mobile_no, "user_types": [{ user_type_id: "7" }],
        "registration_source_id": user?.master_registration_source?.id,
         "branch_id": user?.orgnization_branch?.id ?? 0,
        "organization_id": roleID == 1 ? crntTentId : user?.orgnization_registration.id
      });

      const [arr, arr1, adr, hspadr, arr2] = [[], [], [], [], []];
      const [start_Time, end_Time] = [this.datePipe.transform(sendData.from_time, 'yyyy-MM-dd HH:mm:ss'), this.datePipe.transform(sendData.to_time, 'yyyy-MM-dd HH:mm:ss')];
      [sendData.birth_date, sendData.reg_expiry_date] = [this.datePipe.transform(sendData.birth_date, 'yyyy-MM-dd HH:mm:ss'), this.datePipe.transform(sendData.reg_expiry_date, 'yyyy-MM-dd HH:mm:ss')];
      sendData.charge_amt = this.doctorForm.value.charge_amt == true ? 1 : 0;
      /* if (sendData.doctor_modules?.length > 0) {
        sendData.doctor_modules.forEach(item => arr2.push({ is_active: true, module_id: item.id }));
        sendData.doctor_modules = arr2;
      }
      else
        sendData.doctor_modules = []; */
      if (sendData.hospital_type_id) {
        sendData.hospital_type_id = parseInt(sendData.hospital_type_id);
      }
      delete sendData.docTmplt;
      if (+sendData.id == 0)
        delete sendData.id, delete sendData.user_id;
      else
        isEdit = true;
      if (isEdit)
        this.updateDoctor(sendData, start_Time, end_Time);
      else {
        if (sendData.weekday_id?.length > 0) {
          // if (sendData.weekday_id.length > 0) {
          sendData.weekday_id.forEach(item => arr.push({ id: 0, doctor_id: 0, is_active: true, weekday_id: item.id, from_time: start_Time, to_time: end_Time }))
          // sendData.doctor_available_slots = arr;
          sendData.doctor_available_slots = [];
          // }
        }

        if (sendData.doctor_languages?.length > 0) {
          // if (sendData.doctor_languages.length > 0) {
          sendData.doctor_languages.forEach(item => arr1.push({ id: 0, doctor_id: 0, is_active: true, language_id: item.id }));
          sendData.doctor_languages = arr1;
          // }
        }
        ["home_address", "clinic_address", "hospital_address"].forEach((e, i) => {
          const add = this.doctorForm.get(e).value;
          if (add)
            adr.push({
              id: 0, doctor_id: 0, is_active: true, address_type_id: `${i + 1}`, // address: this.doctorForm.get(e).value,
              address: this.doctor ? (i == 0 ? this.homeAddrs : (i == 1 ? this.clncAddrs : this.hsptlAddrs)) : add,
              display_on_prescription: this.doctorForm.get('prescription_address').value == i + 1 ? true : false
            });
        });
        adr.forEach(f => {
          Object.assign(f, {
            pin_code: this.doctorForm.get('pin_code').value, state_id: this.doctorForm.get('state_id').value, district_id: this.doctorForm.get('district_id').value,
            city_id: this.doctorForm.get('city_id').value, mandal_id: this.doctorForm.get('mandal_id').value, village_id: this.doctorForm.get('village_id').value,
            location_type_id: this.doctorForm.get('location_type_id').value
          })
        });
        sendData.doctor_address = adr;

        const typeOfDoc = this.doctorForm.get('doctor_type_id').value;
        // hspadr.push({
        //   id: 0, is_active: true, doctor_registration: 0, working_in_presently: true,
        //   hospital_name: (+typeOfDoc == 1) ? this.doctorForm.get('hospital_name').value : this.doctorForm.get('hospital_working_in_presently').value,
        //   other_hospital: this.doctorForm.get('other_hospital').value
        // });
        // new map with hospital ID based on hub, center or referral 06-04-2022
        //New changes need to done for get Hospital Name 07-04-2022 chandu

        if (this.centerType === 'Hub') {
          hspadr.push({
            id: 0, is_active: true, doctor_registration: 0, working_in_presently: true,
            hospital_name: this.doctorForm.get('hospital_name').value,
            other_hospital: this.doctorForm.get('other_hospital').value
          });
          sendData.doctor_hospitals = hspadr;
        } else if (this.centerType === 'Center') {
          hspadr.push({
            id: 0, is_active: true, doctor_registration: 0, working_in_presently: true,
            hospital_name: this.doctorForm.get('center_id').value,
            other_hospital: this.doctorForm.get('other_hospital').value
          });
          sendData.doctor_hospitals = hspadr;
        } else if (this.centerType === 'Referral') {
          this.doctorForm.patchValue({
            center_id:sendData.hospital_type_id
          }) 
          

          hspadr.push({
            id: 0, is_active: true, doctor_registration: 0, working_in_presently: true,
            // hospital_name: this.doctorForm.value.referral_hospital,
            hospital_name:this.doctorForm.get('referral_hospital').value,
            other_hospital: this.doctorForm.get('other_hospital').value
          });
          sendData.doctor_hospitals = hspadr;
          console.log(sendData.doctor_hospitals)
        }
      }
      console.log(sendData)
      sendData.age = (new Date()).getFullYear() - (new Date(sendData.birth_date)).getFullYear();
      sendData.address = sendData.home_address || sendData.clinic_address || sendData.hospital_address;
      sendData.department_name = this.departmentList.find(item => item.department_id == sendData.department_id)?.department ?? '';
      this.removedAPIFields(sendData);
      // sendData.aadhar_number = '1234456';
      sendData.first_name = sendData.first_name.toUpperCase();
      if(sendData?.last_name){
        sendData.last_name = sendData?.last_name.toUpperCase();
      } else {
        sendData.last_name='';
      }
     

      if (+sendData.start_hours > 0) {
        if (+sendData.start_hours > +sendData.end_hours) {
          this.toastr.error('Please select End Hours', this.title);
          Common.setFocus('end_hours');
          return;
        }

        if (+sendData.start_minutes > +sendData.end_minutes) {
          this.toastr.error('Please select End Minutes', this.title);
          Common.setFocus('end_minutes');
          return;
        }
        sendData.duty_start_time = `${sendData.start_hours}:${sendData.start_minutes}:00`;
        sendData.duty_end_time = `${sendData.end_hours}:${sendData.end_minutes}:00`;
      }
      else {
        sendData.duty_start_time = '';
        sendData.duty_end_time = '';
      }
      delete sendData.start_hours;
      delete sendData.start_minutes;
      delete sendData.end_hours;
      delete sendData.end_minutes;
      delete sendData.referral_hospital;
      /* if (this.doctorForm.get('prescription_address').value != '')
        sendData.prescription_address = +sendData.prescription_address;
      else
        delete sendData.prescription_address; */
        if(this.centerType === 'Referral'){
          sendData.center_id=sendData.hospital_type_id

        }
      this.registrationService.saveDoctor(sendData).subscribe(res => {
        let apiResponse = res;
        if (apiResponse.status == "OK") {
          let id: number = +(sendData.id ?? 0);
          const notificationData = { email: sendData.email, mobile_no: +sendData.mobile, login_name: sendData.login_name, name: sendData.first_name + ' ' + sendData.last_name }; // `${sendData.first_name} ${sendData.last_name}`
          this.toastr.success(`Record ${(+sendData.id > 0) ? 'Updated' : 'Saved'} Successfully`, 'Doctor Registration');
          if (sessionStorage.getItem('logUsrRoleId') != null) {
            if (+sessionStorage.getItem('logUsrRoleId') == 7) {
              sessionStorage.setItem('DoctorDetails', JSON.stringify(apiResponse.data));
              if (sessionStorage.getItem('userData') != null) {
                let frmId = (<HTMLInputElement>document.getElementById('fromId')).value,
                  payLoad = `doctor/getdoctororhealthworkerrecordbyuserid?roleId=${+sessionStorage.getItem('logUsrRoleId')}&userId=${JSON.parse(sessionStorage.getItem('userData')).id}`;
                this.registrationService.getDHDetails(payLoad).subscribe(res => { sessionStorage.setItem('dtlsDrHw', JSON.stringify(res.data)), 
                updateStatus(frmId, "AVAILABLE", "register"); });
              }
            }
          }
          if (this.doctorForm.value.isHW) {
            let mapData = [];
            //debugger
            mapData.push({
              doctor_id: apiResponse.data.id, health_worker_id: this.doctorForm.value.hlthWorker,
              id: this.doctor?.doctor_health_worker[0]?.id ?? 0
            });
            this.registrationService.mapDoctorHW(mapData).subscribe(() => {
              // this.initFormControls();
            });
          }
          else {
            if (this.doctor?.doctor_health_worker[0]?.id)
            this.registrationService.unMapDoctorHW(this.doctor?.doctor_health_worker[0]?.id).subscribe(() => { });
            //debugger
          }
         
          if (id == 0)
            this.send_Notification(notificationData);
          if (this.uploadFileList.some(f => f.File != null))
            this.saveFile(apiResponse.data.id, id);
          else
            this.showMessage(id);
          // let responseData: any = apiResponse.response;
          // this.saveFile(apiResponse.data.id);
          // this.activeModal.close('changes saved');
          // this.initFormControls();
          // this.toastr.success(`Record ${(+sendData.id > 0) ? 'Updated' : 'Saved'} Successfully`, 'Doctor Registration');
        }
      }, error => this.toastr.error(error.message));
    }
  }
  onChangedForDepartments(id) {
    if (this.centerType === 'Hub') {
      this.hospitalName = this.hubsList.find(a => a.id == id).hospital_name;
      this.doctorForm.value.hospital_name = this.hospitalName;
      this.doctorForm.value.center_type = 'Hub';
      this.getDepartments(id);
    } else if (this.centerType === 'Center') {
      this.hospitalName = this.centerHospitals.find(a => a.id == id).hospital_name;
      this.doctorForm.value.hospital_name = this.hospitalName;
      this.doctorForm.value.center_type = 'Center';
      this.getDepartments(id);
    } else if (this.centerType === 'Referral') {
      this.hospitalName = this.referralHospitals.find(a => a.id == id).hospital_name;
      this.doctorForm.value.hospital_name = this.hospitalName;
      this.doctorForm.value.center_type = 'Referral';
      this.getDepartments(id);
    }


  }
  showMessage(id: number) {
    this.activeModal.close('changes saved');
    this.toastr.success(`Record ${(id > 0) ? 'Updated' : 'Saved'} Successfully`, 'Doctor Registration');
  }

  removedAPIFields(data: any) {
    delete data.pin_code;
    delete data.weekday_id;
    delete data.hospital_name;
    delete data.state_id;
    delete data.location_type_id;
    delete data.district_id;
    delete data.mandal_id;
    delete data.village_id;
    delete data.city_id;
    delete data.home_address;
    delete data.clinic_address;
    delete data.hospital_address;
    delete data.from_time;
    delete data.to_time;
    delete data.address;
    delete data.prescription_address;
    delete data.hospital_working_in_presently;
    delete data.other_hospital;
    // delete data.status;
    delete data.digitalFile;
    delete data.photo;
    delete data.qualificationFile;
    delete data.aadharFile;
    delete data.panFile;
    delete data.isHW;
    delete data.hlthWorker;
  }

  onDoctorChange(doctorTypeId: string, val) {
    if(doctorTypeId=='4'){
    
      this.changeDetection.detach();
      const modalRef = this.modalService.open(AyushDoctorRegistrationComponent, { scrollable: true, size: 'xl' });
      this.changeDetection.reattach();
      var closeExistingModal = document.getElementById('doctype1').click();
    
  }
    this.doctorForm.get('prescription_address').setValue((+doctorTypeId > 0) ? (+doctorTypeId == 1) ? '3' :
      (+doctorTypeId == 3) ? '2' : '' : ''),
      this.doctorForm.get('hospital_name').setValue(null),
      this.doctorForm.get('hospital_working_in_presently').setValue(null);
    if (doctorTypeId == "1") {
      // document.getElementById('center_id').setAttribute("disabled", 'true')
      this.centerType = 'Hub';
      this.refType = '';
      if (val == true) {
        this.getAllHubs();
      }

    };
    if (doctorTypeId == "2") {
     
      // document.getElementById('center_id').removeAttribute("disabled")
      if (!this.isEdit) {
        this.centerType = 'Center';
        this.refType = 'Center';
      }

      this.hubsList = [];
      //Here we can cal referral types API 05-04-2022(PHC,CHC,DH)
      this.getReferralTypes();
      if (val == true) {


        this.getallcenters();
      }

    };
    // this.doctorForm.patchValue({ hospital_name: '', hospital_working_in_presently: '', hospital_address: '', clinic_address: '', home_address: '' });
    this.doctorForm.get('bank_account_number').clearValidators();
    this.doctorForm.get('branch_name').clearValidators();
    this.doctorForm.get('branch_location').clearValidators();
    this.doctorForm.get('ifsc_code').clearValidators();
    this.doctorForm.get('hospital_name').clearValidators();
    this.doctorForm.get('department_id').clearValidators();
    this.doctorForm.get('hospital_working_in_presently').clearValidators();

    if (+doctorTypeId == 2) {
      // this.doctorForm.get('bank_account_number').setValidators([Validators.required]);
      // this.doctorForm.get('branch_name').setValidators([Validators.required]);
      // this.doctorForm.get('branch_location').setValidators([Validators.required]);
      if (!this.isNIG)
        //this.doctorForm.get('ifsc_code').setValidators([Validators.required, Validators.pattern('[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$')]);
        this.doctorForm.get('ifsc_code').clearValidators();
      else
        //this.doctorForm.get('ifsc_code').setValidators(null);
        this.doctorForm.get('hospital_working_in_presently').setValidators([Validators.required]);
      //this.doctorForm.get('hospital_name').setValidators(null);
      this.doctorForm.get('department_id').setValidators(null);
    }
    else if (+doctorTypeId == 1) {
      // this.doctorForm.get('hospital_name').setValidators([Validators.required]);
      this.doctorForm.get('department_id').setValidators([Validators.required]);
      this.doctorForm.get('hospital_working_in_presently').clearValidators();
      // this.doctorForm.patchValue({
      //   center_id: ''
      // })
    }
    else if (+doctorTypeId == 3) {
      this.doctorForm.patchValue({ clinic_address: '1-8-448, Lakshmi Building, 4th, 5th, 6th Floors, Begumpet, Hyderabad, Telangana 500003' })
      this.doctorForm.get('hospital_working_in_presently').setValidators([Validators.required]);
      this.doctorForm.get('hospital_name').clearValidators();
      this.doctorForm.get('department_id').clearValidators();
      // this.doctorForm.get('hospital_name').setValidators(null);
      // this.doctorForm.get('department_id').setValidators(null);
    }

    this.doctorForm.get('hospital_working_in_presently').updateValueAndValidity();
    this.doctorForm.get('bank_account_number').updateValueAndValidity();
    this.doctorForm.get('branch_name').updateValueAndValidity();
    this.doctorForm.get('branch_location').updateValueAndValidity();
    this.doctorForm.get('ifsc_code').updateValueAndValidity();
    this.doctorForm.get('hospital_name').updateValueAndValidity();
    this.doctorForm.get('department_id').updateValueAndValidity();
  }
  //new method added for hub drop down 05-04-2022
  getAllHubs() {
    this.getallRefhospitalTypesList = [];
    this.referralHospitals = [];
    this.centerHospitals = [];
    this.registrationService.getallcenterhospitals('Hub').subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0) {
          this.hubsList = [];
        }
        else
          // this.hubsList = res.data;
          // this.hubsList=res.data.sort((a,b)=>a.hospital_name<b.hospital_name?-1:a.hospital_name>b.hospital_name ?1:0);
          this.hubsList=res.data.sort((a,b)=>a.hospital_name.toLowerCase()<b.hospital_name.toLowerCase()?-1:1); 
      }
    })
   

  }
  //end of hud drop down
  //new method added for Referral Drop Down 05-04-2022
  getReferralTypes() {
    this.registrationService.getallhospitalcentertypes().subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0) {
          this.toastr.info(res.message);
        }
        else {
          this.getallhospitalTypeList = res.data;
        }
      }

    })
  }
  //end of Referral Drop Down
  //Hospital Types on change event 04-04-2022
  changeHospitalType(value) {

    this.centerHospitals=[] 
    if (value === 'Referral') {
      this.centerHospitals = [];
      this.centerType = 'Referral';
      this.hubsList = [];
      this.doctorForm.controls['hospital_type_id'].setValidators(Validators.required);
      this.doctorForm.controls['referral_hospital'].setValidators(Validators.required);
        this.doctorForm.controls['center_id'].clearAsyncValidators();
        this.doctorForm.get('center_id').updateValueAndValidity();
      this.registrationService.getallhospitaltypes().subscribe(res => {
        if (res.status === 'OK') {
          if (res.data == null || res.data.length == 0) {
            this.toastr.info(res.message);
          }
          else {
            // this.getallRefhospitalTypesList = res.data;
            this.getallRefhospitalTypesList=res.data.sort((a,b)=>a.hospital_type>b.hospital_type?1:-1);
          }
        }

      })
    }
    if (value!= 'Referral') {
       this.doctorForm.controls['hospital_type_id'].clearValidators();
       this.doctorForm.get('hospital_type_id').updateValueAndValidity();
       this.doctorForm.controls['referral_hospital'].clearValidators();
       this.doctorForm.controls['referral_hospital'].updateValueAndValidity();
       this.getallcenters();
       this.centerType=value;
    }
  }
  //end of referral type selection

  //Change referral type 06-04-22
  changeReferralType(value) {
    if (value) {
      this.registrationService.getreferralHospitals('Referral', value).subscribe(res => {

        if (res.status === 'OK') {
          if (res.data == null || res.data.length == 0) {
            this.referralHospitals = [];
          }
          else
            // this.referralHospitals = res.data;
          // console.log('Referral hosp info', JSON.stringify(this.referralHospitals) );
          this.referralHospitals=res.data.sort((a,b)=>a.hospital_name>b.hospital_name?1:-1);
        }
      })
    } else {
      this.toastr.error('Provide refferal type');
    }

  }
  //end of change referral type
  getallcenters() {
    this.getallRefhospitalTypesList = [];
    this.hubsList = [];
    this.referralHospitals = [];
    this.registrationService.getallcenterhospitals('Center').subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0) {
          this.centerHospitals = [];
        }
        else
          // this.centerHospitals = res.data;
        //console.log('centerHospitals', JSON.stringify(this.centerHospitals));
        this.doctorForm.controls['center_id'].setValidators([Validators.required])
        this.centerHospitals=res.data.sort((a,b)=>a.hospital_name.toLowerCase()>b.hospital_name.toLowerCase()?1:-1);
        // this.centerHospitals=res.data.sort((a,b)=>a.center_name<b.center_name?-1:a.center_name>b.center_name ?1:0);
      }
    })

  }
  onHospitalName(val: any) {
    // if (val != '')
    //   this.doctorForm.patchValue({ hospital_address: this.hospitalList.filter(f => f.id == val)[0].address });
    // else
    //   this.doctorForm.patchValue({ hospital_address: '' });
    if (val != '' && val != undefined) {
      let item = this.hospitalList.find(f => f.id == val);
      Promise.all([this.locationChanged(item.state_id, 'district'),
      item.locationtype_id == 1 ? (this.locationChanged(item.district_id, 'mandal'),
        this.locationChanged(item.mandal_id, 'village')) : this.locationChanged(item.district_id, 'city')])
        .then(() => {
          this.doctorForm.patchValue({
            hospital_address: this.hospitalList.filter(f => f.id == val)[0].address, state_id: item.state_id,
            location_type_id: item.locationtype_id, district_id: item.district_id, mandal_id: item.mandal_id, village_id: item.village_id,
            city: item.city_id
          });
        });
      this.getDepartments(+val);
    }
    else {
      this.doctorForm.patchValue({
        state_id: '', location_type_id: '', district_id: '', mandal_id: '', village_id: '', city: '', hospital_address: ''
      });
      this.districtList = this.mandalList = this.villageList = this.cityList = [];
    }
  }

  getDepartments(id: number) {
   
    this.departmentList = [];
    this.registrationService.getdepartments(`hospitaldepartments/getbyhospitalid?hospitalId=${id}`).subscribe(res => {

      if (res.status === 'OK') {
        // this.departmentList = res.data;
        this.departmentList=res.data.sort((a,b)=>a.department.toLowerCase()>b.department.toLowerCase()?1:-1);
      }
    });
  }

  onHospitalWorking(type: string, val: any) {
    if (val != '' && this.doctorForm.value.doctor_type_id == '3')
      this.doctorForm.patchValue({ clinic_address: '#1-8-448, Lakshmi Building, 4th, 5th & 6th Floors, Begumpet, Hyderabad, Telangana 500003' });
    else
      this.doctorForm.patchValue({ clinic_address: '' });
    if (type == 'other') {
      if (val != '')
        this.doctorForm.get('hospital_working_in_presently').clearValidators();
      else
        this.doctorForm.get('hospital_working_in_presently').setValidators(Validators.required);
      this.doctorForm.get('hospital_working_in_presently').updateValueAndValidity();
    }
    // if (type == 'dd' && val != '')
    //   this.doctorForm.get('other_hospital').disabled;
    // else
    //   this.doctorForm.get('other_hospital').enabled;
  }

  setAssistantDetailsValidators() {
    const [nameControl, contact_noControl, emailControl] = [this.doctorForm.get('assistant_first_name'), this.doctorForm.get('assistant_contact_number'), this.doctorForm.get('assistant_email')];
    this.doctorForm.get('assistant_detail').valueChanges
      .subscribe(isAssistant => {
        if (isAssistant) {
          nameControl.setValidators([Validators.required]);
          contact_noControl.setValidators([Validators.required, Validators.pattern(this.mobPtrn), Validators.minLength(+this.maxLngth)]);
          emailControl.setValidators([Validators.required]);
        }
        else {
          nameControl.setValidators(null);
          contact_noControl.setValidators(null);
          emailControl.setValidators(null);

          nameControl.setValue('');
          contact_noControl.setValue('');
          emailControl.setValue('');
        }

        nameControl.updateValueAndValidity();
        contact_noControl.updateValueAndValidity();
        emailControl.updateValueAndValidity();
      });
  }

  setLocationTypeValidators() {
    const [city_idControl, mandal_idControl, village_idControl] = [this.doctorForm.get('city_id'), this.doctorForm.get('mandal_id'), this.doctorForm.get('village_id')];
    this.doctorForm.get('location_type_id').valueChanges
      .subscribe(locationType => {
        if (this.isNIG)
          village_idControl.setValue(''), village_idControl.setValidators(null);
        if (+locationType == 2) {
          city_idControl.setValidators(null);
          mandal_idControl.setValue('');
          village_idControl.setValue('');
          mandal_idControl.setValidators(null);
          village_idControl.setValidators(null);
        }
        else if (locationType == 1) {
          city_idControl.setValidators(null);
          city_idControl.setValue('');
          mandal_idControl.setValidators([Validators.required]);
          if (!this.isNIG)
            village_idControl.setValidators([Validators.required]);
        }
        else {
          mandal_idControl.setValue('');
          village_idControl.setValue('');
          city_idControl.setValue('');
          city_idControl.setValidators(null);
          mandal_idControl.setValidators(null);
          village_idControl.setValidators(null);
        }

        city_idControl.updateValueAndValidity();
        mandal_idControl.updateValueAndValidity();
        village_idControl.updateValueAndValidity();
      });
  }

  locationChanged(id: string, locationType: string) {
    let query: string = '';
    let locations = {
      'location': () => {
        // this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        // this.labForm.get('state_id').setValue('');
        this.doctorForm.get('district_id').setValue('');
        this.doctorForm.get('mandal_id').setValue('');
        this.doctorForm.get('village_id').setValue('');
        this.doctorForm.get('city_id').setValue('');
      },
      'state': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        // if (+id > 0)
        query = 'getallstates';
      },
      'district': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          //  query = `getstatebyid?stateId=36`;//QA Pointing
         query = `getstatebyid?stateId=${id}`;//Training Pointing
        // query = 'getallstates';
        this.doctorForm.get('mandal_id').setValue('');
        this.doctorForm.get('city_id').setValue('');
      }
      ,
      'mandal': () => {
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
        this.doctorForm.get('village_id').setValue('');
      },
      'village': () => {
        this.villageList = [];
        if (+id > 0)
          query = `getmandalbyid?mandalId=${id}`;
      },
      'city': () => {
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      }
    };

    locations[locationType]();
    if (query.length > 0)
      this.getMasters(query, locationType);
  }

  getMasters(queryString: string, type: string) {
    this.masterService.getLocations(queryString).subscribe(data => {
      let result = data;
      if (result.status == "OK") {
        let arr: any = result.data;
        let assign_locations = {
          'state': () => this.stateList = arr,//.map(row => this.pick(row, ['id', 'name', 'scheduleCasteType', 'castettype'])),
          'district': () => this.districtList = arr.districts,
          'mandal': () => this.mandalList = arr.mandals,
          'village': () => this.villageList = arr.villages,
          'city': () => this.cityList = arr.cities
        };
        assign_locations[type]();
      }
      this.districtList.sort((a, b) => 0 - (a.district_name > b.district_name ? -1 : 1));

    }, error => this.toastr.error(error));
  }

  keyUp(elementId: string) {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementId)));
    el.value = el.value.replace(/^(0*)/, "");
  }

  onKeyNmbr(type: string, val: string) {
    if (val != '') {
      if (+this.maxLngth == +environment.maxLngthSAGE) {
        if (+val[0] == environment.mobValdValSAGE)
          this.doctorForm.get(type).setValue('');
      }
      if (+this.maxLngth == +environment.maxLngthIND) {
        if (+val[0] < environment.mobValdValIND)
          this.doctorForm.get(type).setValue('');
      }
      if (+this.maxLngth == +environment.maxLngthNIG) {
        if (+val[0] > environment.mobValdValNIG)
          this.doctorForm.get(type).setValue('');
      }
    }
  }

  changeProperty(hashmap: any, existProp: string) {
    Object.keys(hashmap).forEach((key) => {
      hashmap['name'] = hashmap[key];
      delete hashmap[key];
    });
  }

  submitForm() {
    document.getElementById('btnsubmit').click();
  }

  superSecialityDegree(_id: number) {
    this.super_SpecialisationList = (+_id == 0) ? [] : this.superSpecialityDegreeList.find(({ id }) => +id == _id).specializations;
    this.super_SpecialisationList=this.super_SpecialisationList.sort((a,b)=>a.specialization.toLowerCase()>b.specialization.toLowerCase()?1:-1);
  }
  
  singleFileUpload(event: any, fileNumber: number, fileType: string, frmCntrl: string = '') {
    fileNumber = fileNumber - 1;
    let fileExt: string = this.uploadFileList[fileNumber].extensions;
    // Check if any file is selected.
    if (event.target.files.length > 0) {
      const fileList = event.target, fsize = fileList.files.item(0).size, currentfile = Math.round((fsize / (1024 * 5)));
      // Checking type of file
      if (fileList.files[0]) {
        const fileExtension: string = fileList.files[0].name.split('.').pop().toLowerCase();
        // fileExt = fileExtension;
        if (!this.uploadFileList[fileNumber].extensions.split(',').includes(fileExtension)) {
          this.toastr.warning(`Please Select ${fileExt} files only`);
          if (frmCntrl != '') {
            this.doctorForm.get(frmCntrl).clearValidators(), this.doctorForm.get(frmCntrl).setValue(''), // this.cancelFile(fileNumber+1), 
              this.doctorForm.get(frmCntrl).setValidators([Validators.required]), this.doctorForm.get(frmCntrl).updateValueAndValidity();
          }
          return false;
        }
      }
      // The size of the file.
      if (currentfile >= (1024 * 5)) {
        this.toastr.error(fileList.files[0].name + ' too Big, please select a file less than 5MB', 'Doctor Registration');
        return;
      }
      else {
        this.uploadFileList[fileNumber].File = fileList.files.item(0);
        this.uploadFileList[fileNumber].file_name = fileList.files[0].name;
        this.uploadFileList[fileNumber].fileType = fileType;
        if (this.doctor) {
          let f = this.doctor.doctor_documents.filter(f => f.file_type_id == +this.uploadFileList[fileNumber].fileType);
          this.uploadFileList[fileNumber].id = f.length > 0 ? f[0].id : 0;
        }
      }
    }
  }

  saveFile(id: string, record_id: number) {
    let sendAPIfiles = [];
    this.uploadFileList.forEach(item => {
      if (item.File)
        sendAPIfiles.push(this.fileService.uploadFile(Common.form_Data(item.id.toString(), item.File, id, 'DOCTOR', item.fileType)));
    });

    forkJoin([...sendAPIfiles]).subscribe(res => console.log, err => console.log, () => { this.showMessage(record_id); });
  }

  getAllLabPharmacyHospital() {
    const lab = this.registrationService.getAll('lab/getalllabregistrations');
    const pharmacy = this.registrationService.getAll('pharmacy/getallrecords');
    const languages = this.masterService.getAll('getalllanguages');
    const weekDays = this.masterService.getAll('getallweekdays');
    const gender = this.masterService.getAll('getallgenders');
    const locationType = this.masterService.getAll('getalllocationtypes');
    const marital = this.masterService.getAll('getallmaritalstatus');
    const title = this.masterService.getAll('getalltitle');
    const visitStatus = this.masterService.getAll('getallvisitstatus');
    const addressTypes = this.masterService.getAll('getallAddresstypes');
    const qualifications = this.masterService.getAll('getallqualifications');
    const specialization = this.masterService.getAll('getallspecialization');
    const superSpecialityDegree = this.masterService.getAll('getallsuperspecialityqualifications');
    const bankName = this.masterService.getAll('getallmasterbankdetails');
    const hospital = this.registrationService.getAll('hospital/getallhospitalrecords');

    forkJoin([lab, pharmacy, languages, weekDays, gender, locationType, marital, title, visitStatus, addressTypes, qualifications,
      specialization, superSpecialityDegree, bankName, hospital]).subscribe({
        next: res => {
          res.forEach((list, ind) => {
            if (list.status === 'OK') {
              if (list.data == null || list.data.length == 0)
                console.log('No records available');
              // this.toastr.info('No records available', 'Pharmacy List');
              else {
                const assignList = {
                  '0': () => this.labList = list.data,
                  '1': () => this.pharmacyList = list.data,
                  '2': () => this.languagesList = list.data,
                  '3': () => this.weekDaysList = list.data,
                  '4': () => this.genderList = list.data,
                  '5': () => this.locationTypeList = list.data,
                  '6': () => this.maritalList = list.data,
                  '7': () => this.titleList = list.data,
                  '8': () => this.visitStatusList = list.data,
                  '9': () => this.addressTypesList = list.data,
                  '10': () => this.qualificationsList = list.data,
                  '11': () => this.specializationList = list.data,
                  '12': () => this.superSpecialityDegreeList = list.data,
                  '13': () => this.bankNamesList = list.data,
                  '14': () => this.hospitalList = list.data,
                }
                assignList[ind]();
              }           
            }            
          });
       },
        error: err => console.error('something wrong occurred: ', err),
        complete: () => {
          [this.isShwDS, this.isShwHQ, this.isShwPic] = [false, false, false],
            // this.qualificationsList.sort((a, b) => a.id - b.id), 
            this.qualificationsList=this.qualificationsList.sort((a,b)=>a.qualification.toLowerCase()>b.qualification.toLowerCase()?1:-1);
            // this.specializationList=this.specializationList.sort((a,b)=>a.specialization.toLowerCase()>b.specialization.toLowerCase()?1:-1);
            this.editDoctor();
        }
      });
  }

  cancelFile(ind: number, frmCntrl: string = '', fileTypeId: number = 0) {
    let view_File = this.uploadFileList[ind - 1];
    view_File.File = null, view_File.file_name = '';
    if (this.doctor) {
      if (fileTypeId > 0) {
        this.doctor.doctor_documents.forEach((e, i) => {
          if (e) {
            if (e.file_type_id == fileTypeId)
              this.doctor.doctor_documents[i] = null;
          }
        });
        this.uploadFileList.forEach(e => {
          if (e) {
            if (+e.fileType == fileTypeId)
              e.document_path = '', e.file_name = '', e.id = 0;
          }
        });
        if (fileTypeId == 8)
          this.isShwDS = false;
        if (fileTypeId == 9)
          this.isShwHQ = false;
        if (fileTypeId == 5)
          this.isShwPic = false;
      }
    }
    if (frmCntrl != '') {
      this.doctorForm.get(frmCntrl).clearValidators(), this.doctorForm.get(frmCntrl).setValue(''),
        this.doctorForm.get(frmCntrl).setValidators([Validators.required]), this.doctorForm.get(frmCntrl).updateValueAndValidity();
    }
  }

  viewFile(ind: number) {
    let view_File = this.uploadFileList[ind - 1], imagePath = '';
    const doctor_id = +this.doctorForm.get('id').value;
    const fileExtension: string = view_File.file_name.split('.').pop().toLowerCase();
    if (fileExtension == 'pdf')
      (view_File.File) ? Common.showPDF_File(URL.createObjectURL(view_File.File)) : Common.showPDF_File(`${this.registrationService.viewFilePath}DOCTOR/${doctor_id}/${view_File.name}/${view_File.document_path}`);
    else if (['jpg', 'jpeg', 'bmp', 'png'].includes(fileExtension)) {
      const modalRef = this.modalService.open(ImagePreviewComponent, { size: 'lg', scrollable: true });
      if (view_File.File) {
        const reader = new FileReader();
        reader.readAsDataURL(view_File.File); // read file as data url
        // called once readAsDataURL is completed
        reader.onload = (event1: any) => {
          imagePath = event1.target.result;
          modalRef.componentInstance.image = imagePath;
        };
      }
      else {
        imagePath = `${this.registrationService.viewFilePath}DOCTOR/${doctor_id}/${view_File.name}/${view_File.document_path}`;
        modalRef.componentInstance.image = imagePath;
      }
    }
  }

  editDoctor() {
    if (this.doctor) {
      this.onQualification(this.doctor?.qualification_id);
      //alert('edit DOCX..')
      //console.log('doctor Edit',JSON.stringify(this.doctor));
      var user = this.authServ.currentUserValue;
      if (user?.user_role_mapping[0].user_type_id.id == 1) {
        this.isEdit = false;
      }
      else{
        this.isEdit = true;
      }
      this.hide = 1, this.doctor.doctor_documents.forEach(f => {
        const docu = this.uploadFileList.find(e => e.fileType == f.file_type_id);
        if (docu) {
          docu.id = f.id;
          docu.document_path = f.document_path;
          docu.file_name = f.file_name;
          if (docu.fileType == '8')
            this.isShwDS = true;
          if (docu.fileType == '9')
            this.isShwHQ = true;
          if (docu.fileType == '5')
            this.isShwPic = true;
          // [this.isShwDS, this.isShwHQ, this.isShwPic] = [docu.fileType == '8', docu.fileType == '9', docu.fileType == '5'];
        }
      });
      this.fileValidations(), this.doctorForm.get('account_activated').enabled;
      const editDoctor = Object.assign({}, this.doctor);
      this.refType = editDoctor.center_type;
      this.centerType = editDoctor.center_type;
      this.hospitalId = editDoctor.hospital_name;
      let address = (editDoctor.doctor_address.length > 0) ? editDoctor.doctor_address : null;
      let [state_id, location_type_id, district_id, mandal_id] = (address) ? [address[0].state_id, address[0].location_type_id, address[0].district_id, address[0].mandal_id] : [0, 0, 0, 0];
      let docTmplts: any[] = editDoctor.doctor_modules.filter(f => f.is_active == true);
      Promise.all([this.locationChanged(state_id, 'district'), location_type_id == 1 ? (this.locationChanged(district_id, 'mandal'),
        this.locationChanged(mandal_id, 'village')) : this.locationChanged(district_id, 'city')]).then(() => {
          let [loc, hosp] = [editDoctor.doctor_address, editDoctor.doctor_hospitals];
          this.doctorForm.patchValue({
            state_id: loc[0]?.state_id, district_id: loc[0]?.district_id, mandal_id: loc[0]?.mandal_id, village_id: loc[0]?.village_id,
            city_id: loc[0]?.city_id, pin_code: loc[0]?.pin_code, location_type_id: loc[0]?.location_type_id,
            home_address: loc.filter(f => f.address_type_id == 1 && f.is_active == true)[0]?.address ?? '',
            clinic_address: loc.filter(f => f.address_type_id == 2 && f.is_active == true)[0]?.address ?? '',
            hospital_address: loc.filter(f => f.address_type_id == 3 && f.is_active == true)[0]?.address ?? '',
            prescription_address: loc.filter(f => f.display_on_prescription == true)[0]?.address_type_id,
            isHW: this.doctor?.doctor_health_worker?.length > 0 ? this.doctor?.doctor_health_worker[0]?.is_active : false,
            hlthWorker: this.doctor?.doctor_health_worker?.length > 0 ? (this.doctor?.doctor_health_worker[0]?.is_active == true ? this.doctor?.doctor_health_worker[0]?.id : '') : '',
          }, { onlySelf: true, emitEvent: false });
          //(+typeOfDoc == 1) ? this.doctorForm.get('hospital_name').value : this.doctorForm.get('hospital_working_in_presently').value,
          this.doctorForm.patchValue(editDoctor),
            this.doctorForm.patchValue({
              hospital_name: ((hosp.length > 0) && (editDoctor.doctor_type_id == 1 || editDoctor.doctor_type_id == 2)) ? hosp[0].hospital_name : '',
              hospital_working_in_presently: ((hosp.length > 0) && (editDoctor.doctor_type_id != 1)) ? hosp[0].hospital_name : '',
              other_hospital: (hosp.length > 0) ? hosp[0].other_hospital : ''

            }, { emitEvent: true, onlySelf: false });
          if (new Date(editDoctor.reg_expiry_date) < new Date())
            this.maxdate = new Date(editDoctor.reg_expiry_date);
          else
            this.maxdate = new Date();
          [this.homeAddrs, this.clncAddrs, this.hsptlAddrs] = [loc.filter(f => f.address_type_id == 1)[0]?.address ?? '',
          loc.filter(f => f.address_type_id == 2)[0]?.address ?? '', loc.filter(f => f.address_type_id == 3)[0]?.address ?? ''];
          this.onDoctorChange(editDoctor.doctor_type_id, false), this.setWeekDays(editDoctor.doctor_available_slots),
            this.setLanguages(editDoctor.doctor_languages), this.superSecialityDegree(editDoctor.super_qualification_id),
            // this.setDrTmplt(editDoctor.doctor_modules),

            this.doctorForm.patchValue({
              from_time: this.gettimeFormat(editDoctor.doctor_available_slots[0]?.from_time),
              to_time: this.gettimeFormat(editDoctor.doctor_available_slots[0]?.to_time),
              weekday_id: this.selectedItems,
              doctor_languages: this.selectedItems_language,
              doctor_modules: this.selectedItems_module,
              birth_date: new Date(editDoctor.birth_date),
              reg_expiry_date: new Date(editDoctor.reg_expiry_date),
              docTmplt: docTmplts[docTmplts.length - 1]?.module_id ?? '', // editDoctor.doctor_modules[editDoctor.doctor_modules.length-1]?.module_id,
            });
          //New lines added for edit doctor based on editDoctor 06-04-2022 #chandu
          // alert('%%%%%%%'+editDoctor.doctor_hospitals.hospital_name);
          //console.log('Doctor Data', JSON.stringify(editDoctor));
          if (editDoctor.center_type === 'Hub') {
            this.refType = '';
            this.centerHospitals = [];
            this.referralHospitals = [];
            this.getAllHubs();
            setTimeout(() => {
              this.getDepartments(parseInt(editDoctor.doctor_hospitals[0].hospital_name));
              if (editDoctor.doctor_hospitals[0].hospital_name) {
                this.doctorForm.patchValue({
                  hospital_name: editDoctor.doctor_hospitals[0].hospital_name,
                  department_id: editDoctor.department_id
                })
  
              }
            }, 1500);
          
            //department_id
           
          


            // this.doctorForm.get('hospital_name').setValue(editDoctor.doctor_hospitals[0].hospital_name);
          }
          if (editDoctor.center_type === 'Center') {


            this.centerType = 'Center';

            // this.referralHospitals=[];
            // this.getallRefhospitalTypesList=[];
            // this.hubsList=[];
            this.getallcenters();
            this.getDepartments(parseInt(editDoctor.doctor_hospitals[0].hospital_name));
            //this.hospitalForm.controls['hospital_type_id'].clearValidators();
            this.doctorForm.get('center_type').setValue(editDoctor.center_type);
            this.doctorForm.controls['hospital_type_id'].clearValidators();
            this.doctorForm.get('center_id').setValue(parseInt(editDoctor.doctor_hospitals[0].hospital_name));
            this.doctorForm.get('department_id').setValue(editDoctor.department_id);
            // this.getallcenters();
            //this.doctorForm.get('center_id').setValue(editDoctor.center_id);
            //  console.log('center obj',this.centerHospitals );
            const fvalue: any = this.centerHospitals.find(a => a.id == editDoctor.doctor_hospitals[0].hospital_name);
            //  console.log('fvalue',fvalue);

            this.hospitalName = fvalue;
            // this.doctorForm.get('center_id').setValue(editDoctor.doctor_hospitals[0].hospital_name);
            // this.selected_hospital = editDoctor.doctor_hospitals[0].hospital_name
            // this.doctorForm.patchValue({
            //   center_id: editDoctor.doctor_hospitals[0].hospital_name,
            //   department_id:editDoctor.department_id
            // })

            // alert(editDoctor.doctor_hospitals.hospital_name);

          } if (editDoctor.center_type === 'Referral') {
            this.centerHospitals = [];
            this.getallRefhospitalTypesList
            this.changeHospitalType('Referral');
            this.getDepartments(parseInt(editDoctor.doctor_hospitals[0].hospital_name));
            this.changeReferralType(parseInt(editDoctor.hospital_type_id));
            // this.changeReferralType(parseInt(editDoctor.hospital.hospital_type_id));
            //  console.log('editDoctor.doctor_hospitals[0].hospital_name',editDoctor.doctor_hospitals[0].hospital_name);
            this.doctorForm.get('department_id').setValue(editDoctor.department_id);
            this.doctorForm.get('hospital_type_id').setValue(editDoctor.hospital_type_id);
            this.doctorForm.get('referral_hospital').setValue(editDoctor.doctor_hospitals[0].hospital_name);
          }  else{
            this.getDepartments(parseInt(editDoctor.doctor_hospitals[0].hospital_name));
          }
          if (editDoctor.preferred_lab_id != null)
            this.doctorForm.get('lab_id').setValue(editDoctor.preferred_lab_id.id);

          if (editDoctor.preferred_pharmacy_id != null)
            this.doctorForm.get('pharmacy_id').setValue(editDoctor.preferred_pharmacy_id.id);
          // if (hosp.length > 0)
          //   this.getDepartments(+hosp[0].hospital_name);
          //   this.doctorForm.get('department_id').setValue(editDoctor.department_id);
          /* let docMods = [];
          if (editDoctor.doctor_modules) {
            let editDM: any = this.doctorlist.filter((f: any) => f.id == editDoctor.doctor_modules[editDoctor.doctor_modules.length-1].module_id)[0];
            docMods.push({ id: editDM.id, module_name: editDM.module_name });
          } */
          // binding empty values while editing record....
          this.doctorForm.patchValue({
            home_address: loc.filter(f => f.address_type_id == 1)[loc.filter(f => f.address_type_id == 1 && f.is_active == true).length - 1]?.address ?? '',
            clinic_address: loc.filter(f => f.address_type_id == 2)[loc.filter(f => f.address_type_id == 2 && f.is_active == true).length - 1]?.address ?? '',
            hospital_address: loc.filter(f => f.address_type_id == 3)[loc.filter(f => f.address_type_id == 3 && f.is_active == true).length - 1]?.address ?? '',
            hospital_name: ((hosp.length > 0) && (editDoctor.doctor_type_id == 1)) ? hosp[0].hospital_name : '',
            hospital_working_in_presently: ((hosp.length > 0) && (editDoctor.doctor_type_id != 1)) ? hosp[hosp.length - 1].hospital_name : '',
            pin_code: loc[loc.length - 1]?.pin_code ?? '', // doctor_modules: [...docMods]
          });

          if (editDoctor.duty_start_time) {
            const startTime = editDoctor.duty_start_time.split(':');
            const endTime = editDoctor.duty_end_time.split(':');

            this.doctorForm.patchValue({
              start_hours: startTime[0],
              start_minutes: startTime[1],
              end_hours: endTime[0],
              end_minutes: endTime[1],
            }, { emitEvent: true, onlySelf: false })
          }
          // console.clear();
          // console.log(this.doctorForm)
          // setTimeout(() => { this.doctorForm.get('doctor_modules').setValue(docMods); }, 100);
        });
    }
    else
      this.maxdate = new Date(), this.isEdit = false;
  }

  onAdrsChng(type: string, val: any) {
    if (type == 'ha')
      this.homeAddrs = val; // this.doctorForm.get('home_address').value;
    else if (type == 'ca')
      this.clncAddrs = val; // this.doctorForm.get('clinic_address').value;
    else
      this.hsptlAddrs = val; // this.doctorForm.get('hospital_address').value;
  }

  gettimeFormat(time: string) {
    const date_time = `${this.datePipe.transform(new Date(), 'yyyy-MM-dd')} ${time}`;
    return new Date(date_time);
  }

  setWeekDays(weeks: any[]) {
    weeks.forEach(day => {
      const dayname = this.weekDaysList.find(item => item.id == day.weekday_id && day.is_active);
      if (dayname) {
        const item = { id: dayname.id, weekday: dayname.weekday };
        this.selectedItems.push(item);
      }
    });
  }

  setLanguages(languages: any[]) {
    languages.forEach(day => {
      const dayname = this.languagesList.find(item => item.id == day.language_id && day.is_active);
      if (dayname) {
        const item = { id: dayname.id, language_name: dayname.language_name };
        this.selectedItems_language.push(item);
      }
    });
  }

  onChngDT(dtId: any) {
    if (dtId) {
      const template: any = this.doctorlist.find((item: any) => item.id == dtId);
      if (template) {
        this.doctor.doctor_modules = template;
        let dtVal: any[] = [], data: any = this.doctor, modId = 0;
        if (data)
          modId = data.doctor_modules ? (data.doctor_modules.length > 0 ? data.doctor_modules[0].id : 0) : 0;
        dtVal.push({ id: modId, module_id: template.id, is_active: true });
        this.doctorForm.get('doctor_modules').setValue(dtVal);
      }
    }
    else {
      if (this.doctor) {
        let result: any[] = [];
        this.doctor.doctor_modules.forEach(e => { if (e.is_active) result.push({ id: e.id, module_id: e.module_id, is_active: false }); });
        if (result.length > 0)
          this.doctorForm.get('doctor_modules').setValue(result);
      }
    }
  }

  setDrTmplt(drMods: any[]) {
    drMods.forEach(dt => {
      const template: any = this.doctorlist.find((item: any) => item.id == dt.module_id);
      if (template) {
        const item = { id: template.id, module_name: template.module_name };
        this.selectedItems_module.push(item);
      }
    });
  }
 
  onQualification(id:any){
    this.masterService.getAll(`get-specialization-by-qualification/${id}`).subscribe(res =>{
      this.newSpecializationList = res.data
    });




  }
  QualificationChanged(id: string) {
    this.doctorForm.patchValue({
      specialization_id :''
    })
    this.newSpecializationList = (+id > 0) ? this.qualificationsList.find(item => item.id == id).specializations : [];
    this.newSpecializationList=this.newSpecializationList.sort((a,b)=>a.specialization.toLowerCase()>b.specialization.toLowerCase()?1:-1);
  
    if (+id == 1)
      this.doctorForm.get('specialization_id').clearValidators();
    else
      this.doctorForm.get('specialization_id').setValidators([Validators.required]);

    this.doctorForm.get('specialization_id').updateValueAndValidity();
  }

  updateDoctor(sendData: any, start_Time: string, end_Time: string) {
    const old_record = JSON.parse(JSON.stringify(this.doctor));
    let [doctor_id, doctor_available_slots, doctor_languages, doctor_address, doctor_hospitals] = [sendData.id, [], [], [], []];
    this.weekDaysList.forEach(w => {
      let old = (old_record.doctor_available_slots.length > 0) ? old_record.doctor_available_slots.find(e => e.weekday_id == w.id) : null,
        new1 = (sendData.weekday_id?.length > 0) ? sendData.weekday_id.find(e => e.id == w.id) : null;

      if (old && new1)
        doctor_available_slots.push({ id: old.id, doctor_id: doctor_id, is_active: true, weekday_id: w.id, from_time: start_Time, to_time: end_Time })
      else if (old)
        doctor_available_slots.push({ id: old.id, doctor_id: doctor_id, is_active: false, weekday_id: w.id, from_time: start_Time, to_time: end_Time })
      else if (new1)
        doctor_available_slots.push({ id: 0, doctor_id: doctor_id, is_active: true, weekday_id: w.id, from_time: start_Time, to_time: end_Time })
    })
    sendData.doctor_available_slots = doctor_available_slots;
    this.languagesList.forEach(l => {
      let old = (old_record.doctor_languages.length > 0) ? old_record.doctor_languages.find(e => e.language_id == l.id) : null,
        new1 = (sendData.doctor_languages.length > 0) ? sendData.doctor_languages.find(e => e.id == l.id) : null;

      if (old && new1)
        doctor_languages.push({ id: old.id, doctor_id: doctor_id, is_active: true, language_id: l.id });
      else if (old)
        doctor_languages.push({ id: old.id, doctor_id: doctor_id, is_active: false, language_id: l.id });
      else if (new1)
        doctor_languages.push({ id: 0, doctor_id: doctor_id, is_active: true, language_id: l.id });
    });
    sendData.doctor_languages = doctor_languages;

    ["home_address", "clinic_address", "hospital_address"].forEach((e, i) => {
      const add = this.doctorForm.get(e).value, ind = (i + 1);
      let old = old_record.doctor_address.find(e => e.address_type_id == ind);
      const prescription_address = this.doctorForm.get('prescription_address').value == ind ? true : false;

      if (old && add)
        doctor_address.push({ id: old.id, doctor_id: doctor_id, is_active: true, address_type_id: ind, address: add, display_on_prescription: prescription_address });
      else if (old)
        doctor_address.push({ id: old.id, doctor_id: doctor_id, is_active: false, address_type_id: ind, address: (i == 0 ? this.homeAddrs : (i == 1 ? this.clncAddrs : this.hsptlAddrs)), display_on_prescription: prescription_address });
      else if (add)
        doctor_address.push({ id: 0, doctor_id: doctor_id, is_active: true, address_type_id: ind, address: add, display_on_prescription: prescription_address });
    });

    doctor_address.forEach(f => {
      Object.assign(f, {
        pin_code: this.doctorForm.get('pin_code').value, state_id: this.doctorForm.get('state_id').value, district_id: this.doctorForm.get('district_id').value,
        city_id: this.doctorForm.get('city_id').value, mandal_id: this.doctorForm.get('mandal_id').value, village_id: this.doctorForm.get('village_id').value,
        location_type_id: this.doctorForm.get('location_type_id').value
      })
    });
    sendData.doctor_address = doctor_address;
   
    const typeOfDoc = this.doctorForm.get('doctor_type_id').value;
    //######chandu####### center doctor registration getting error before, below lines fixed that issues
    if (sendData.center_type === 'Center') {
      doctor_hospitals.push({
        id: (old_record.doctor_hospitals.length > 0) ? old_record.doctor_hospitals[0].id : 0,
        is_active: true, doctor_registration: doctor_id, working_in_presently: true,
        hospital_name: (+typeOfDoc == 1) ? this.doctorForm.get('center_id').value : this.doctorForm.get('center_id').value,
        other_hospital: this.doctorForm.get('other_hospital').value
      });
    } else if (sendData.center_type === 'Referral') {
      doctor_hospitals.push({
        id: (old_record.doctor_hospitals.length > 0) ? old_record.doctor_hospitals[0].id : 0,
        is_active: true, doctor_registration: doctor_id, working_in_presently: true,
        hospital_name: (+typeOfDoc == 1) ? this.doctorForm.get('referral_hospital').value : this.doctorForm.get('referral_hospital').value,
        other_hospital: this.doctorForm.get('other_hospital').value
      });
    }
    else {
      doctor_hospitals.push({
        id: (old_record.doctor_hospitals.length > 0) ? old_record.doctor_hospitals[0].id : 0,
        is_active: true, doctor_registration: doctor_id, working_in_presently: true,
        hospital_name: (+typeOfDoc == 1) ? this.doctorForm.get('hospital_name').value : this.doctorForm.get('hospital_name').value,
        other_hospital: this.doctorForm.get('other_hospital').value
      });
    }

    sendData.doctor_hospitals = doctor_hospitals;
  }

  fileValidations() {
    if (this.doctor.doctor_documents.length > 0) {
      if (this.doctor.doctor_documents.filter(f => f.file_type_id == 8).length > 0)
        this.doctorForm.get('digitalFile').clearValidators();
      else
        this.doctorForm.get('digitalFile').setValidators([Validators.required]);
      if (this.doctor.doctor_documents.filter(f => f.file_type_id == 9).length > 0)
        this.doctorForm.get('qualificationFile').clearValidators();
      else
        this.doctorForm.get('qualificationFile').setValidators([Validators.required]);
      if (this.doctor.doctor_documents.filter(f => f.file_type_id == 5).length > 0)
        this.doctorForm.get('photo').clearValidators();
      else
        this.doctorForm.get('photo').setValidators([Validators.required]);
    }
    this.doctorForm.get('aadharFile').clearValidators(), this.doctorForm.get('panFile').clearValidators(),
      this.doctorForm.get('digitalFile').updateValueAndValidity(), this.doctorForm.get('qualificationFile').updateValueAndValidity(),
      this.doctorForm.get('photo').updateValueAndValidity(), this.doctorForm.get('aadharFile').updateValueAndValidity(),
      this.doctorForm.get('panFile').updateValueAndValidity();
  }

  send_Notification(payload: { email: string, mobile_no: number, name: string, login_name: string }) {
    const postData = {
      "email": payload.email, "login_name": payload.login_name, "mobile_no": payload.mobile_no, "user_id": 7,
      "user_type": "Doctor", "name": payload.name.toUpperCase()
    };
    this.notificationService.sendNotification(postData).subscribe();
  }

  chkDocDocs(): boolean {
    let blnVal = false;
    if (this.doctor?.doctor_documents.length > 0)
      blnVal = this.doctor?.doctor_documents?.filter(f => f.file_type_id == 5)?.file_name != '';
    return blnVal;
  }
  getallcentersGrid() {
    let payload = {
      "state_id": 0,
      "district_id": 0,
      "location_type_id": 0,
      "mandal_id": 0

    }
    this.registrationService.getAllCenters(payload).subscribe(res => {
      // this.getAllcenters=data.data;
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0) {
          this.centerList = []
          // this.toastr.info(res.message);
        }
        else
          this.centerList = res.data;

      }
      else
        this.centerList = []
    })
  }
  ngAfterContentChecked() {
    this.ref.detectChanges();
  }
}