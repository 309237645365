import { Component, OnInit, Input } from '@angular/core';
import { ImagePreviewComponent } from '../image-preview/image-preview.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { ToastrService } from 'ngx-toastr';
import {combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { forEach } from 'jszip';
@Component({
  selector: 'app-view-file',
  templateUrl: './view-file.component.html',
  styleUrls: ['./view-file.component.css']
})
export class ViewFileComponent implements OnInit {

  @Input() title: string;
  @Input() patientInteractionId: string;
  @Input() docId:any;
  @Input() benificiaryId:any;
  @Input() isPocScreen: any;
  @Input() is_self:any;
  @Input() insuranceFilesList:any;
  image: string = '';
  origin: string = '';
  previewFileList: any
  previewFileListNew: Array<any> = [];
  result:any;
  insuranceFilesShow: boolean;
  insuranceFilesListNew: any=[];
  // benId:any;
  // doctorId:any;

  constructor(public activeModal: NgbActiveModal
    , private toastr: ToastrService
    , private registrationService: RegistrationService
    , private modalService: NgbModal) {
    // this.origin = this.registrationService.viewFilePath;
  }

  ngOnInit(): void {
    
       if(this.insuranceFilesList.length && this.insuranceFilesList.length>0){
        let fileList=[];
        this.insuranceFilesList.map(re=>{
          
          if(re.file_type==='AadharCard'){
            fileList.push(re.document_path_full);
            if(fileList.length>1){
              let data={'fileName':re.file_type,'date':re.created_on,'file':fileList,'fullObj':re};
              this.insuranceFilesListNew.push(data);
            }
            
          }else{
            let data={'fileName':re.file_type==='IdentityCard'?'EmployeeCard':re.file_type,'date':re.created_on,
            'file':re.document_path_full,'fullObj':re};
            this.insuranceFilesListNew.push(data);
          }
          
        
        });

       this.insuranceFilesShow=true;
       }else{
        this.onViewFiles();
        this.insuranceFilesShow=false;
       }
      
  }

  // sushma view file 03-08-2022
  onViewFiles(){
    if(this.is_self){
    this.registrationService.getviewFile(`beneficiary/getBeneficiaryInvestigationFiles?patientInteractionId=${this.patientInteractionId}&beneficiaryId=${this.benificiaryId}&viewFile=true`).subscribe(res => {
      if (res.status === 'OK') {
        if (res.data) {
          this.previewFileList = res.data;
        }
      }
    },
      );}
    else{
      {
        this.registrationService.getviewFile(`beneficiary/getBeneficiaryInvestigationFiles?patientInteractionId=${this.patientInteractionId}&memberId=${this.benificiaryId}&viewFile=true`).subscribe(res => {
          if (res.status === 'OK') {
            if (res.data) {
              this.previewFileList = res.data;
            }
          }
        },
          );}
    }
    
  }

  openModal() {
    const modalRef = this.modalService.open(ImagePreviewComponent, { size: 'lg', scrollable: true });
    modalRef.componentInstance.image = this.origin;
  }

  showImageInsuranceFile(item:any ,file: string) {
    let fileType: string = item.document_path.split('.').pop().toLowerCase();
   this.origin=sessionStorage.getItem('tenantUrl')+file;

    (fileType == 'pdf') ? this.showPDF_File() : this.openModal();
  }
  showImageFile(item:any ,file: string) {
    let fileType: string = file.split('.').pop().toLowerCase();
    this.origin = `${this.registrationService.viewFilePath}${file}`;

    (fileType == 'pdf') ? this.showPDF_File() : this.openModal();
  }

  showPDF_File() {
    window.open(this.origin, '_blank', 'left=300,top=50,width=700,height=600,toolbar=1,resizable=0');
  }
}