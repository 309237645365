import { VerifyOTPComponent } from './../verify-otp/verify-otp.component';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { MasterService } from 'src/app/shared/services/master.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { UserService } from 'src/app/shared/services/user.service';

import { forkJoin, Subscription } from 'rxjs';
import { Avail } from 'src/app/common';
import { ApiService } from 'src/app/shared/services/api.service';
import { environment } from 'src/environments/environment';
import { shareReplay, share } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

declare let $: any;
declare const register: any;
declare const call: any;
declare const statusRegister: any;

@Component({
  selector: 'app-login-old',
  templateUrl: './login-old.component.html',
  styleUrls: ['./login-old.component.css'],
  providers: [DatePipe]
})

export class LoginOldComponent implements OnInit {

  loginForm: FormGroup; loading = false; submitted = false; returnUrl: string; error = '';

  name: string = ''; toId: string = ''; fromId: string = ''; userType: number = 1; complaints: string = 'Fever'; myId:any=null;
  isDoctor: boolean = true; doctor_name: string = ''; doctor_id: string = ''; isRegistered: boolean = false;
  
  logourl = ''; qlfnId = ''; qlfnName = ''; expn = ''; splznName = ''; hwId = ''; hwName = ''; docId = ''; appUrl = ''; roleId = 0;
  ovDtls: any; isActiveMode: boolean = false; isRmbrChk: boolean = false; dataDRHW: any; excRefCode: any; grpRefCode: any; indRefCode: any;
  moduleList: Array<{ id: number, module_name: string, canView: boolean }> = []; screenList: Array<any> = []; permissionList: Array<any> = [];
  tenantData: any; OTP_user: boolean = false; showSessionExpired: boolean = false; unSubscription$: Subscription[] = [];
  mdlClsBtn = document.getElementById('btnVideoCallModalClose'); appSrce = ''; lgnUrl = ''; clientName = ''; tollFree = '';
  isNIG = !environment.isNIG; pwrdBy = environment.isNIG ? 'eCLINIC234' : 'Dhanush Infotech';

  constructor(private toastr: ToastrService
    , private formBuilder: FormBuilder
    , private route: ActivatedRoute
    , config: NgbModalConfig
    , private modalService: NgbModal
    , private router: Router
    , private authenticationService: AuthenticationService
    , private masterService: MasterService
    , private registrationService: RegistrationService
    , private notificationService: NotificationService
    , private apiSvc: ApiService
    , private userServices: UserService
    , private dtPipe: DatePipe
  ) {
    this.appUrl = location.href.includes('localhost') ? Avail.lclUrl : location.origin;
    config.backdrop = 'static';
    config.keyboard = false;
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue)
      this.router.navigate(['/']);
  }

  get f() { return this.loginForm.controls; }

  ngOnDestroy(): void {
    this.unSubscription$.forEach(sub => sub.unsubscribe());
  }

  ngOnInit() {
    sessionStorage.removeItem('islogged'), this.isRmbrChk = false;
    // console.log('cookies'), console.log((<HTMLInputElement>document.getElementById('lgnUsrName')).value),
    //   console.log((<HTMLInputElement>document.getElementById('lgnUsrPswd')).value), console.log(document.cookie);
    //, console.log($.cookie('email')), console.log($.cookie('password'));
    // this.toastr.show(`browser_type="${window.orientation}"`);
    // });
    this.authenticationService.appClntSubject.subscribe(s => { this.clientName = s }), this.authenticationService.appSrceSubject.
      subscribe(s => { this.appSrce = s, this.lgnUrl = `${location.origin}${environment.assetsURL}${this.appSrce}${environment.bgLogin}` }),
      this.authenticationService.tollFreeSubject.subscribe(s => this.tollFree = s),
    this.getOVDtls(), this.loginForm = this.formBuilder.group({ username: ['', Validators.required], password: ['', Validators.required] }),
      this.setFocus('username'); // , this.setFocus('password'); // setTimeout(() => { this.setFocus('password'); }, 100); 
    $('input').blur(function () {
      ($(this).val()) ? $(this).addClass('has-content') : $(this).removeClass('has-content');
    });
    
    // const sub = this.authenticationService.sessionExpired.subscribe(e => this.showSessionExpired = e);
    this.unSubscription$.push(this.authenticationService.sessionExpired.subscribe(e => this.showSessionExpired = e));

    if (this.mdlClsBtn)
      setTimeout(() => { this.mdlClsBtn.click(); }, 1000);
    this.authenticationService.bannerSubject.next([]), this.authenticationService.drugsSubject.next('');
    /* if (sessionStorage.getItem('lgnSsn') != null) {
      let lgnSsnData = JSON.parse(sessionStorage.getItem('lgnSsn'));
      this.loginForm.get('username').setValue(lgnSsnData.usrName), this.loginForm.get('password').setValue(lgnSsnData.usrPswd), this.isRmbrChk = lgnSsnData.isChk;
    } */
    if (sessionStorage.getItem('lgnSsn') != null) {
      let lgnSsnData = JSON.parse(sessionStorage.getItem('lgnSsn'));
      this.loginForm.get('username').setValue(lgnSsnData.usrName), this.loginForm.get('password').setValue(lgnSsnData.usrPswd), this.isRmbrChk = true;
      $('#username').addClass('has-content'), $('#password').addClass('has-content');
    }
    // setTimeout(() => { alert('eTab Testing') }, 10000);
    $(document).ready(function () {
      var link = document.querySelector("link[rel*='icon']");
      link.setAttribute("type", 'image/x-icon');
      link.setAttribute("rel", 'shortcut icon');
      link.setAttribute("href", `${location.origin}/assets/vmLogos/${environment.webSrce}/appicon.ico`); // link.setAttribute("href", 'http://localhost:4567/assets/vmLogos/swashodhan/appicon.ico');
      document.getElementsByTagName('head')[0].appendChild(link);
      /* var newExcitingAlerts = (function () {
        var oldTitle = document.title;
        var msg = "New!";
        var timeoutId;
        var blink = function() { document.title = 'eTab Testing Eshwar'; };
        var clear = function() {
            clearInterval(timeoutId);
            document.title = oldTitle;
            window.onmousemove = null;
            timeoutId = null;
        };
        return function () {
            if (!timeoutId) {
                timeoutId = setInterval(blink, 1000);
                window.onmousemove = clear;
            }
        };
      }()); */
      /* var PageTitleNotification = {
        Vars: {
          OriginalTitle: document.title,
          Interval: null
        },    
        On: function(notification, intervalSpeed) {
          var _this = this;
          _this.Vars.Interval = setInterval(function() {
            document.title = (_this.Vars.OriginalTitle == document.title)
              ? notification : _this.Vars.OriginalTitle;
          }, (intervalSpeed) ? intervalSpeed : 1000);
          // document.body.style.backgroundColor = 'yellow';
        },
        Off: function() {
          clearInterval(this.Vars.Interval);
          document.title = this.Vars.OriginalTitle;   
        }
      }
      setTimeout(() => { PageTitleNotification.On("eee-VIDMED..!", 1000); }, 10000); */
    });
  }

  setFocus(elementName: string, value: string = '') {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementName)));
    el.value = value;
    el.focus();
  }

  checkValidation(): boolean {
    if ((this.loginForm.get('username').value || '') === '') {
      this.toastr.error('Please enter valid username', 'Login');
      this.setFocus('username');
      return false;
    }
    else if ((this.loginForm.get('password').value || '') === '') {
      this.toastr.error('Please enter valid password', 'Login');
      this.setFocus('password');
      return false;
    }
    return true;
  }

  onChngUN() {
    let uN: string = this.loginForm.get('username').value;
    this.loginForm.get('username').setValue(uN.trim())
  }

  onSubmit() {
    if (this.tenantData)
      sessionStorage.removeItem('isAppURL');
    this.isActiveMode = true, this.submitted = true;
    if (this.checkValidation() == false)
      return false;
    if (this.loginForm.invalid) {
      return;
    }
    else {
      let tntId = this.tenantData?.id ?? null;
      this.authenticationService.login(this.loginForm.get('username').value, '', this.loginForm.get('captchaText').value, '', tntId, true).pipe(share()).
        subscribe({
          next: x => {
            if (x.status == 'success') {
              let user = x.response;
              if (!user.account_activated) {
                this.toastr.error('Your login is in Inactive mode', 'Login Details');
                return;
              }
              if (!user.is_active) {
                this.toastr.error('Your login is Deleted', 'Login Details');
                return;
              }
              sessionStorage.setItem('logUsrName', `${this.loginForm.get('username').value}`);
              sessionStorage.setItem('logUsrRoleId', this.authenticationService.roleId);
              sessionStorage.setItem('userData', JSON.stringify(user));
              this.OTP_user = false;
              if (this.isRmbrChk) {
                let ssnLgnUsr = { usrName: this.loginForm.get('username').value, usrPswd: this.loginForm.get('password').value };
                sessionStorage.setItem('lgnSsn', JSON.stringify(ssnLgnUsr)); // sessionStorage.setItem('lgnSsn', JSON.stringify(ssnLgnUsr));
                // $.cookie('email', this.loginForm.get('username').value), $.cookie('password', this.loginForm.get('password').value);
                // (<HTMLInputElement>document.getElementById('lgnUsrName')).value = this.loginForm.get('username').value,
                //   (<HTMLInputElement>document.getElementById('lgnUsrPswd')).value = this.loginForm.get('password').value;
                // document.cookie = `${this.loginForm.get('username').value}:${this.loginForm.get('password').value}`;
              }
              else {
                sessionStorage.removeItem('lgnSsn'); // sessionStorage.removeItem('lgnSsn'); // , $.cookie('email', ''), $.cookie('password', '');
                // (<HTMLInputElement>document.getElementById('lgnUsrName')).value = '',
                //   (<HTMLInputElement>document.getElementById('lgnUsrPswd')).value = '';
                // document.cookie
              }
              
              if ((this.authenticationService.roleId > 1) && ('123456' === this.loginForm.get('password').value))
                this.OTP_user = true, this.sendOTP('&operation=forgotPassword'), this.openModal();
              else {
                if (this.authenticationService.roleId != 1)
                  this.isActiveMode = user.account_activated;
                // this.isActiveMode = user.is_active;

                // [this.doctor_id, this.doctor_name] = [user.id, `${user?.first_name} ${user?.last_name}`];
                this.getFileTypeMaster();
                if (this.authenticationService.roleId != 1)
                  this.getOrgDetails();
                // else
                //   console.log('got value :=', x);
                  // sessionStorage.setItem('TENANT_ID':`${currentUser.orgnization_registration.id}`);
                if (this.authenticationService.roleId == 7) {
                  this.getDoctorDetails(user.id, user);
                  // this.router.navigate(['home/appointment']);
                }
                this.authenticationService.updateSession(false);
                if (this.OTP_user)
                  return;
                /* if (this.isActiveMode)
                  this.loadPermissions();
                else
                  this.toastr.error('User is in Inactive mode.', 'Login Details'); */
                if (this.authenticationService.roleId != 7)
                  this.loadPermissions();
                if (!this.isActiveMode)
                  this.toastr.error('User is in Inactive mode.', 'Login Details');
              }
            }
            else {
              // return this.toastr.error(x.message?x.message:x)
              //  this.toastr.error(x)
              let errMsg: string = x.message ? x.message : x;
              if (errMsg != '') {
                errMsg = errMsg.toLowerCase();
                if (errMsg.includes("already logged in")) {
                  this.apiSvc.post(`${environment.loginAPI}user/logout?userId=${x.user_id}`, null).
                    subscribe(res => {
                      if (res)
                        this.onSubmit();
                    });
                }
                else
                  this.toastr.error(errMsg);
              }
              else
                this.toastr.error(errMsg);
            }
          },
          error: err => {
            let errMsg: string = err?.response?.message ?? '';
            if (errMsg != '') {
              errMsg = errMsg.toLowerCase();
              if (errMsg.includes("already logged in")) {
                this.apiSvc.post(this.appUrl + 'user/logout', { "login_name": this.loginForm.get('username').value }).
                  subscribe(res => {
                    if (res)
                      this.onSubmit();
                  });
              }
              else
                this.toastr.error(errMsg);
            }
            else
              this.toastr.error(errMsg);
          }
          // error: err => {
          //   console.info(err); // console.log(err), this.toastr.error(err?.response ?? err)
          //   if (err?.response == 'User already logged in from other device') {
          //     this.apiSvc.post(this.appUrl + environment.loginAPI + 'user/logout', { "login_name": this.loginForm.get('username').value }).
          //       subscribe(
          //         res => {
          //           if (res)
          //             this.onSubmit();
          //         });
          //   }
          //   else
          //    this.toastr.error(`123`);
          //     // this.toastr.error(err.error);
          // }, // error: err => console.error('something wrong occurred: ', err),
          // error: HttpErrorResponse => {
          //   expect(HttpErrorResponse.message);
          //   console.log(HttpErrorResponse.message,"abc")
          // },
          // complete: () => {
          //   this.authenticationService.updateSession(false);
          //   this.authenticationService.updateSession(false);
          //   if (this.OTP_user)
          //     return;        
          //   if (this.isActiveMode)
          //     this.loadPermissions();
          //   else
          //     this.toastr.error('User is in Inactive mode.', 'Login Details');
          // }
        });

    }

    //   this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    //   this.loading = true;
    //   this.authenticationService.login(this.f.username.value, this.f.password.value)
    //     .subscribe(data => { this.router.navigate([this.returnUrl]); },
    //       error => { this.error = error; this.loading = false; this.toastr.error(error) });
  }

  getDoctorDetails(id: any, user: any) {
    this.registrationService.getByID('doctor/getdoctorrecordbyuserid?userId=', id).subscribe(res => {
      if (res.data.length > 0) {
        sessionStorage.setItem('DoctorDetails', JSON.stringify(res.data[0]));
        this.masterService.getAll(`getqualificationbyid?qualificationId=${res.data[0].qualification_id}`).subscribe(qfcn => {
          [this.doctor_id, this.doctor_name, this.qlfnId, this.expn, this.qlfnName, this.grpRefCode, this.indRefCode, this.excRefCode] =
            [res.data[0].id, user?.first_name + ' ' + user?.last_name, res.data[0].qualification_id, res.data[0].years_experience,
            qfcn.data == null ? 'MBBS' : qfcn.data.qualification, res.data[0].group_reference_code, res.data[0].individual_reference_code,
            res.data[0].exclusive_reference_code];
          let deDt = +this.dtPipe.transform(res.data[0]?.reg_expiry_date, "yyyyMMdd"), tdDt = +this.dtPipe.transform((new Date()), "yyyyMMdd");
          if (deDt < tdDt)
            document.getElementById('drRegExpiry')?.click();
          else {
            if (this.isActiveMode)
              this.webRTC_method();
          }
        });
      }
    },
      err => { this.toastr.error(err) },
      () => { });
  }

  getOrgDetails() {
    let id = this.authenticationService.currentUserValue.orgnization_registration?.id ?? '0';
    sessionStorage.setItem('TENANT_ID', id);
    if (id != '')
      this.userServices.getById(`organization/getorganizationsettingbyorgid?orgId=${+id}`).subscribe(res => {
        let response = res.data;
        sessionStorage.setItem('OrgDetails', JSON.stringify(response));
      },
        err => { this.toastr.error(err.message) });
  }

  getFileTypeMaster() {
    this.masterService.getAll('getallfiletypes').subscribe(res => {
      let data = res.data;
      sessionStorage.setItem('FileTypeMaster', JSON.stringify(data));
    },
      err => { this.toastr.error(err) });
  }

  webRTC_method() {
    if (this.isDoctor)
      [this.toId, this.fromId, this.name, this.userType, this.complaints,this.myId] = ['', `doc_${this.doctor_id}`, this.doctor_name, 1, '',this.doctor_id];
    else
      [this.toId, this.fromId, this.name, this.userType, this.complaints, this.myId] = ['doc_100', 'pat_100', 'Uma Mahesh', 0, 'Fever','100'];

    this.isRegistered = true;
    (<HTMLInputElement>document.getElementById('sessionName')).value = this.fromId;
    (<HTMLInputElement>document.getElementById('fromId')).value = this.fromId;
    (<HTMLInputElement>document.getElementById('toId')).value = this.toId;

    let orgData = JSON.parse(sessionStorage.getItem('userData'));
    (<HTMLInputElement>document.getElementById('newUserId')).value = `org_${orgData.orgnization_registration.id}_${orgData.orgnization_registration.orgnization_name}_${this.fromId}_${this.name}_${(new Date()).getTime()}`;

    if (+this.userType === 1) {
      this.complaints = '';
      let logUsr = {
        toId: this.toId, fromId: this.fromId, name: this.name, userType: this.userType, complaints: this.complaints,mydId:this.myId,
        qlfn: this.qlfnId, expn: this.expn, qlfnName: this.qlfnName, grCode: this.grpRefCode, irCode: this.indRefCode, erCode: this.excRefCode,
      };
      sessionStorage.setItem('webSkt', JSON.stringify(logUsr)), sessionStorage.setItem('islogged', '1');
      register(this.toId, this.fromId, this.name, this.userType, this.complaints, this.qlfnId, this.expn, this.myId);
      statusRegister(this.qlfnId, this.expn, this.qlfnName, this.grpRefCode, this.indRefCode, this.excRefCode);
      if (this.isActiveMode)
        this.loadPermissions();
    }
    else {
      this.complaints = 'Fever';
      register(this.toId, this.fromId, this.name, this.userType, this.complaints);
      setTimeout(() => this.callToDoctor(), 1000);
    }
  }

  callToDoctor() {
    //chandu 24-04-2022
    call(this.toId, this.fromId, this.name, this.complaints);
    this.router.navigate(['home/poc'])
      .then((e) => {
        if (e) {
          // answerCall(fromId, toId);
          console.log("Navigation is successful!");
        } else {
          console.log("Navigation has failed!");
        }
      });
  }

  sendOTP(oprtn: string = '') {
    if (this.tenantData)
      sessionStorage.removeItem('isAppURL'), this.notificationService.send_OTP_Notification(`email=${this.loginForm.get('username').value}&TENANT_ID=${this.tenantData?.id}${oprtn}`).subscribe();
    else
      this.toastr.error('No tenant details found', 'kindly Contact Admin');
  }

  openModal(type: number = 1) {
    const modalRef = this.modalService.open(VerifyOTPComponent);
    modalRef.componentInstance.title = 'OTP Verification';
    modalRef.componentInstance.email_id = this.loginForm.get('username').value;
    modalRef.result.then(result => {
      (type == 1) ? this.openModal_ChanePassword() : this.openModal_forgotPassword();
    }, (reason) => { });
  }

  openModal_ChanePassword() {
    const modalRef = this.modalService.open(ChangePasswordComponent);
    modalRef.componentInstance.title = 'Change Password';
    modalRef.result.then(result => {

    }, (reason) => { });
  }

  openModal_forgotPassword() {
    if (this.tenantData) {
      const modalRef = this.modalService.open(ForgotPasswordComponent);
      modalRef.componentInstance.title = 'Forgot Password';
      modalRef.componentInstance.userId = this.loginForm.get('username').value;
      modalRef.componentInstance.tenantId = this.tenantData ? this.tenantData.id.toString() : '';
      modalRef.result.then(result => { }, (reason) => { });
    }
    else
      this.toastr.error('No tenant details found', 'kindly Contact Admin');
  }

  forgotpaasword() {
    if ((this.loginForm.get('username').value || '') === '') {
      this.toastr.error('Please enter valid username', 'Login');
      this.setFocus('username');
      return false;
    }
    else
      this.sendOTP('&operation=forgotPassword'), this.openModal(2);
  }

  loadModules() {
    this.moduleList = [], this.screenList.forEach(item => {
      let isExists = this.moduleList.find(({ id }) => id == item.module.id);
      let perm = this.permissionList.find(p => p.screenId == item.id);
      [item.can_delete, item.can_insert, item.can_update, item.can_view] =
        perm ? [perm.canDelete, perm.canInsert, perm.canUpdate, perm.canView] : [false, false, false, false];
      if (isExists == null)
        this.moduleList.push({ id: item.module.id, module_name: item.module.module_name, canView: false });
    });
    //Module can enable or disable
    this.moduleList.forEach(m => { let arr = this.screenList.filter(s => m.id == s.module.id);
      if (arr)
        m.canView = arr.some(a => a.can_view == true);
    });
    sessionStorage.setItem('modules', btoa(JSON.stringify(this.moduleList)));
    sessionStorage.setItem('screen', btoa(JSON.stringify(this.screenList)));
    if (this.authenticationService.roleId == 1)       // Super Admin
      this.router.navigate(['home/doctorList']);
    else if (this.authenticationService.roleId == 2)  // Admin
      this.router.navigate(['home/roleList']);
    else if (this.authenticationService.roleId == 7)  // Doctor
      this.router.navigate(['home/appointment']);
    else
      this.router.navigate(['home']);
  }

  async loadPermissions() {
    let roleId = this.authenticationService.roleId;
    const screens = await this.userServices.getAll('screen/getall');
    const permissions = await this.userServices.getById(`userrolescreenpermission/get_by_roleid?role_id=${roleId}`);
    forkJoin([screens, permissions]).subscribe({
      next: res => {
        res.forEach((list, ind) => {
          if (list.status === 'OK') {
            if (list.data == null || list.data.length == 0)
              console.log('No records available');
            else
              this.screenList = list.data;
          }
          else if (list.status === 'success')
            this.permissionList = list.response;
        });
      },
      error: error => console.log(error.message),
      complete: () => { this.loadModules(); }
    })
  }

  getOVDtls() {
    sessionStorage.removeItem('isAppURL'), this.userServices.getOpenViduDtls(1).toPromise().then((ov: any) => {
      let ovDtls = ov.data[0]; sessionStorage.setItem('ovDtls', JSON.stringify(ovDtls)),
        (<HTMLInputElement>document.getElementById('ovUrl')).value = ovDtls.openvidu_url,
        (<HTMLInputElement>document.getElementById('ovKey')).value = ovDtls.secret_key,
        (<HTMLInputElement>document.getElementById('apUrl')).value = this.userServices.appUrl;
      (<any>window).OPENVIDU_SERVER_URL = ovDtls.openvidu_url;
      (<any>window).OPENVIDU_SERVER_SECRET = ovDtls.secret_key;
      (<any>window).vidmed_url = `${this.userServices.appUrl}telecon/`;
      if (this.mdlClsBtn)
        setTimeout(() => { this.mdlClsBtn.click(); }, 1000);
      this.getTenantDetails(); // console.clear(), console.log(`url=${this.ovDtls.openvidu_url},key=${this.ovDtls.secret_key},appUrl=${this.appUrl}`);      
    });
  }

  getTenantDetails() {
    let webURL: string = location.href.includes('localhost') ? environment.applicationURL : location.origin;
    sessionStorage.setItem('isAppURL', '0'), this.tenantData = null, this.userServices.getTentantDtls(webURL).toPromise().then((tnt: any) => {
      this.tenantData = tnt.data, sessionStorage.setItem('tenantDtls', JSON.stringify(tnt));
    });
  }

  onTollFree() {
    if (window.orientation == undefined) {
      let selBox = document.createElement('textarea'); selBox.style.position = 'fixed', selBox.style.left = '0', selBox.style.top = '0',
        selBox.style.opacity = '0', selBox.value = this.tollFree, selBox.focus(), document.body.appendChild(selBox), selBox.select(),
        document.execCommand('copy'), document.body.removeChild(selBox), this.toastr.success('Copied to clipboard.!');
    }
    else {
      let link = document.createElement('a');
        link.setAttribute('href', `tel:${this.tollFree}`) ,link.setAttribute('visibility', 'hidden'), link.setAttribute('display', 'none'),
        link.setAttribute('target', '_blank'), document.body.appendChild(link), link.click()
    }
  }

}