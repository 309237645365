import { Component, HostListener } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavigationStart, Router } from '@angular/router';
import { ConnectionService } from 'ng-connection-service';
import { Observable, merge, of, fromEvent, Subscription, Subject } from 'rxjs';
import { mapTo, takeUntil } from 'rxjs/operators';

import { ModalContentComponent } from './modal-content/modal-content.component';
import { RegistrationService } from './shared/services/registration.service';
import { AuthenticationService } from './shared/services/authentication.service';
import { ApiService } from './shared/services/api.service';
import { HttpHeaders } from '@angular/common/http';
export let browserRefresh = false;

declare let $: any;
declare const answerCall: any;
declare let JqueryReady: any;
declare let joinSession: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {

  title = 'vidmed-ui';
  status = 'ONLINE';
  isConnected = true;
  userId: any;
  online$: Observable<boolean>;
  isConnectionAvailable: boolean;
  lstDrgs = []; drgTxt = '';
  isGnm = false;
  sourcetitle="Patient";
  myType: any;
  subscription_refresh: Subscription;
  pocType:string;
  roleId: string;
  constructor(private toastr: ToastrService
    , private modalService: NgbModal
    , private connectionService: ConnectionService
    , private registrationService: RegistrationService
    , private router: Router
    , private titleSvc: Title
    , private apiSvc: ApiService
    , private authSvc: AuthenticationService) {
         this.roleId= sessionStorage.getItem('logUsrRoleId');
         this.subscription_refresh = router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          browserRefresh = !router.navigated;
        }
    });
    this.check_internet(), this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    )
    this.networkStatus(), authSvc.bannerSubject.subscribe(s => {
      this.lstDrgs = s;
      if (s.length > 0)
        s.forEach(e => { this.drgTxt += this.drgTxt == '' ? `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${e}` : `,&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${e}`; });
      // s.forEach(e => { this.drgTxt += this.drgTxt == '' ? `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${e.brand_name}` : `,&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${e.brand_name}`; });
    });
    // authSvc.drugsSubject.subscribe(s => this.drgTxt = s); // titleSvc.setTitle(this.appTtl);
    authSvc.appTtlSubject.subscribe(s => { titleSvc.setTitle(s) }), 
    authSvc.isSubjectGNM.subscribe(s => this.isGnm = s);
   this.myType= sessionStorage.getItem('myType');
   if(this.myType==='doctor'){
   this.sourcetype()
   } else{
     this.sourcetypeclick();
   }
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event) 
  
  {
    var value = event.target.value;
    if (value.length === 0 && event.which === 32) event.preventDefault();
  }
  open() {
    const modalRef = this.modalService.open(ModalContentComponent, { scrollable: true });
    modalRef.componentInstance.name = 'World';
  }
  // setTimeout(() => {
  //   this.pocType= sessionStorage.getItem('pocType');

  //   console.log('getPOCT',sessionStorage.getItem('pocType'));  
  // }, 100);
  acceptCall() {
    sessionStorage.setItem('call_type','Video');
    sessionStorage.setItem('call_type_id',''+1);
    let fromId = (<HTMLInputElement>document.getElementById('fromId')).value, toId = (<HTMLInputElement>document.getElementById('toId')).value;
    this.router.navigate([`home/${sessionStorage.getItem('pocType')}`])
      .then((e) => {
        let ws = JSON.parse(sessionStorage.getItem('webSkt')); // JqueryReady(); // answerCall(fromId, toId);
        Promise.all([JqueryReady()]).then(res => { answerCall(fromId, toId, ws.expn, ws.qlfnName, ws.grCode, ws.irCode, ws.erCode); joinSession(); });
        if (e)
          console.log("Navigation is successful!");
        else
          console.log("Navigation has failed!");
      });
  }

  check_internet() {
    this.connectionService.monitor().subscribe(isConnected => { this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = "Network is available  now";
        this.toastr.success(this.status);
      }
      else {
        this.status = "Network not available ";
        this.toastr.error(this.status);
      }
    })
  }

  declineCall() {
    this.toastr.error('Patient has disconnected the call');
  }
  declineDocCall(){
    this.toastr.error('Doctor has disconnected the call');
  }
  viduException(){
    this.toastr.error('OpenVidu exception occurred');
  }

  public networkStatus() {
    this.online$.subscribe(value => this.isConnectionAvailable = value);
  }

  onRec(isStrt: boolean) {
    if (isStrt) {
      let ovUrl = (<HTMLInputElement>document.getElementById('ovUrl')).value, ovKey = (<HTMLInputElement>document.getElementById('ovKey')).value,
        ssnId = (<HTMLInputElement>document.getElementById('forceRecordingId')).value, srPL = JSON.stringify({ "session": ssnId,
        "name": ssnId, "outputMode": 'COMPOSED', "hasAudio": true, "hasVideo": true, "resolution": "1280x720", "recordingLayout": "BEST_FIT" });
      const hO = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + ovKey) };
      this.apiSvc.post3(`${ovUrl}api/recordings/start`, srPL, hO).subscribe(res => { console.log(res), console.log('eRecord started'); });
    }
    else
      alert('eRecord stopped');
  }

  endAgntCall() {
    this.router.navigate(['home/agentlistcall']);   
  }

  onPatient_connection(){
    this.toastr.error('Patient/Agent side connection lost');
  }
  disconnect_connection(){
    this.toastr.error('Connection Problem, Oops! Trying to reconnect to the session');
  }
 pocopenrote(){
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      // this.router.navigate(['/home/poc']);
      this.router.navigate([`home/${sessionStorage.getItem('pocType')}`])
    });
  }
  sourcetype(){
    this.sourcetitle="Doctor";
  }
  sourcetypeclick(){
    this.sourcetitle="Patient";
  }
  // sourcetypenew(){
  //   this.sourcetitle="Doctor";
  // }
  // sourcetypeclicknew(){
  //   this.sourcetitle="Doctor";
  // }
  ngOnDestroy() {
    this.subscription_refresh.unsubscribe();
  }
}