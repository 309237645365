import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
// const httpOptions = {
//     headers: new HttpHeaders({ 'Content-Type': 'application/json' })
// };
@Injectable({ providedIn: 'root' })

export class MasterService {
    // url: string = 'http://registration.dhanushinfotech.com/esubcenter_master/';
    mastersAPI: string = '';
    spinnerCount: number = 0;
    constructor(private api: ApiService) {
        this.mastersAPI = environment.mastersAPI;
    }

    getLocations(queryString: string): Observable<any> { return this.api.get(`${this.mastersAPI}${queryString}`); }
    getAll(queryString: string): Observable<any> { return this.api.get(`${this.mastersAPI}${queryString}`); }
    getAPI(queryString: string): Observable<any> { return this.api.get(`${queryString}`); }
    // new method added for get status types 29-03-2023
    getStatusTypes(queryString: string): Observable<any> { return this.api.get(`${this.mastersAPI}${queryString}`); }
   
    //investigation
    saveInvestigation(data: any): Observable<any> { return this.api.post(`${this.mastersAPI}saveandupdateinvestigation`, data); }
    deleteByinvestId(id: number): Observable<any> { return this.api.get(`${this.mastersAPI}deleteinvestigation?investigationId=${id}`); }

    //drug
    saveDrug(data: any): Observable<any> { return this.api.post(`${this.mastersAPI}saveandupdatedrug`, data); }
    deleteBydrugId(id: number): Observable<any> { return this.api.get(`${this.mastersAPI}deletedrug?drugId=${id}`); }
    deleteByCoupon(id: number): Observable<any> { return this.api.get(`${this.mastersAPI}deletecoupon?couponId=${id}`); }
    deleteByCompany(id: number): Observable<any> { return this.api.get(`${this.mastersAPI}mastercompany/deletebyid?id=${id}`); }
    
    // save(methodName: string, data: any): Observable<any> { return this.api.post(`${this.mastersAPI}${methodName}`, data); }
    //company
    getAllCompanies(apiMethod: string): Observable<any> { return this.api.get(`${this.mastersAPI}${apiMethod}`); }

    save(methodName: string, data: any): Observable<any> { return this.api.post2(`${this.mastersAPI}${methodName}`, data); }
    searchbyIdMasters(queryString: string): Observable<any> { return this.api.get(`${this.mastersAPI}${queryString}`); }

    //deletbystate
    deleteById(queryString: string): Observable<any> { return this.api.get(`${this.mastersAPI}${queryString}`); }
    
    //getallpocoptions
    getAllPocOptions(): Observable<any> { return this.api.get(`${this.mastersAPI}getallmastermoduletype`); }
    getalldrugs(): Observable<any> { return this.api.get(`${this.mastersAPI}getalldrugtypes`); }
    getDrugDetails(id: Number): Observable<any> { return this.api.get(`${this.mastersAPI}drugtypemapping/getdrugbydrugtypeid?drugTypeId=${id}`); }
    getAllpaymenttypes(): Observable<any> { return this.api.get(`${this.mastersAPI}vle/getall`); }
    getAllDistrict(): Observable<any> { return this.api.get(`${this.mastersAPI}getalldistricts`); }
    activateSpinner() {
        // this.spinnerCount++;
        // if (this.spinnerCount == 1) {
        //   this.appComponent.activateSpinner()
    
        // }
      }
    
      deactivateSpinner() {
        // if (!this.spinnerCount) return
        // this.spinnerCount--
        // if (!this.spinnerCount) this.appComponent.deactivateSpinner();
        // if (this.spinnerCount < 0) {
        //   this.spinnerCount = 0;
        //   this.appComponent.deactivateSpinner()
        // }
    
      }
      getallhitypes():Observable<any>{
        return this.api.get(`${this.mastersAPI}ndhm/hitype/getallhitypes`);
        // https://telemedicine-uk-training.vmedtm.com/masters/ndhm/hitype/getallhitypes
      }
      
      getallpurposeofuse():Observable<any>{
        return this.api.get(`${this.mastersAPI}ndhm/purpose/getallpurposeofuse`);
        // https://telemedicine-uk-training.vmedtm.com/masters/ndhm/hitype/getallhitypes
      }
    
      /////////////////////////////////////spinner///////////////////////////////////////
    
    
}