import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })

export class UserService {
  loginApi: string;
  get(arg0: string) {
    throw new Error('Method not implemented.');
  }

    userAPI = ''; tntAPI = ''; appUrl = (location.href.includes('localhost') ? environment.applicationURL : location.origin) + '/';
    logTnntId: number = null;

    
    constructor(private api: ApiService, private authSvc: AuthenticationService) {
        this.userAPI = environment.userApi, this.tntAPI = (location.href.includes('localhost') ? environment.tenantURL : location.origin + '/');
        this.loginApi = environment.loginAPI, this.tntAPI = (location.href.includes('localhost') ? environment.tenantURL : location.origin + '/');
      }
    getuser(queryString: string): Observable<any> { return this.api.get(`${environment.loginAPI}${this.userAPI}${queryString}`); }
    getAll(queryString: string): Observable<any> { return this.api.get(`${environment.loginAPI}${this.userAPI}${queryString}`); }
    getAllNew(queryString: string): Observable<any> { return this.api.post(`${environment.loginAPI}${this.userAPI}${queryString}`, null); }
    
    saveReferal(apimethod: string, data: any): Observable<any> { 
      let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
      if (tntId != null)
      return this.api.post(`${environment.loginAPI}${this.userAPI}${apimethod}?TENANT_ID=${tntId}`, data); 
    }
    save(apimethod: string, data: any): Observable<any> { return this.api.post(`${environment.loginAPI}${this.userAPI}${apimethod}`, data); }
    updatefeatures(apimethod: string, data: any): Observable<any> { return this.api.post(`${this.userAPI}${apimethod}`, data); }

    save2(apimethod: string, data: any): Observable<any> { return this.api.post2(`${this.userAPI}${apimethod}`, data); }
    getById(queryString: string): Observable<any> { return this.api.get(`${environment.loginAPI}${this.userAPI}${queryString}`); }
    deleteByCorporate(id: number): Observable<any> { return this.api.get(`${environment.loginAPI}${this.userAPI}corporate/deletebyid?corporateId=${id}`); }
    deleteByCompany(id: number): Observable<any> { return this.api.get(`${environment.loginAPI}${this.userAPI}orgnizationcompany/deletebyid?id=${id}`); }
    getOrgById(orgId: number): Observable<any> { return this.api.get(`${environment.loginAPI}${this.userAPI}corporate/getcorporatebyorgid?orgnizationId=${orgId}`);}
    getUserPermissionByOrgId(orgId: number): Observable<any> {
        return this.api.get(`${environment.loginAPI}user/get-features/${orgId}`);
      }
    getUserPermissionallByOrgId(orgId: number): Observable<any> {
        return this.api.get(`${environment.loginAPI}user/get-features-all/${orgId}`);
      }
      UpdatePermissions(queryString: string, data:any): Observable<any> {
        return this.api.post(`${environment.loginAPI}${this.userAPI}${queryString}`, data);
        return this.api.post(`${this.loginApi}${this.userAPI}${queryString}`, data);
      }
      savePermissions(queryString: string, data:any): Observable<any> {
        return this.api.post(`${this.loginApi}${this.userAPI}${queryString}`, data);
      }
      saveTenantModule(queryString: string, data:any): Observable<any> {
        return this.api.post(`${this.userAPI}${queryString}`, data);
      }
      getTenantModule(queryString: string,): Observable<any> {
        return this.api.get(`${this.userAPI}${queryString}`);
      }
      submitFeaturesByOrgId(orgId: number): Observable<any> {
        return this.api.get(`${environment.loginAPI}user/getuserpermissioncomparebyorgId/${orgId}`);
      }
      getAllActiveDocs(orgId: number): Observable<any> {
        return this.api.get(`telecon/doctorstatus/getall?TENANT_ID=${orgId}`);
      }
      getCaptcha(): Observable<any> {
        return this.api.get(`vidmed-login/captcha/captchaImg`);
      }
      getAllfeatures(): Observable<any> { return this.api.get(`${environment.loginAPI}user/getallmasterfeature`); }
    getOrgCompyById(orgId: number): Observable<any> { return this.api.get(`${this.userAPI}orgnizationcompany/getcompanyByorgid?orgnizationId=${orgId}`);}
    getOpenViduDtls(orgId: number) { return this.api.get(`${this.userAPI}openvidu/getopenvidudetailsbyorgid?organizationId=${orgId}`); }
    getTentantDtls(tntUrl: string) { return this.api.get(`${this.tntAPI}user/organization/getorganizationbydomain?domainName=${tntUrl}`); }
    deletecouponId(id: number): Observable<any> { return this.api.get(`${this.userAPI}deletecoupon?couponId=${id}`); }
    getOrgFTs(apimethod: string): Observable<any> { return this.api.get(`${environment.loginAPI}${this.userAPI}${apimethod}`); }
    setRfrshTkn(apimethod: string): Observable<any> { return this.api.get(`${this.userAPI}${apimethod}`); }

    // GAM 

    editPlans(orgId: number): Observable<any> {
      return this.api.get(`${environment.loginAPI}user/organization/getfreeandpaymentbyorgid?orgId=${orgId}`);
    }
    getCalls(data:any): Observable<any> {
      return this.api.post(`${environment.loginAPI}user/organization/savefreecallandvlepayment`,data);
    }
    getCurrency(orgId: number): Observable<any> {
      return this.api.get(`${environment.loginAPI}masters/getallcurrencies?TENANT_ID=${orgId}`);
    }
    getallpaymentTypes(): Observable<any> {
      return this.api.get(`masters/paymenttypes/getall`);
    }
}