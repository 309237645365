import { VerifyOTPComponent } from './../verify-otp/verify-otp.component';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { MasterService } from 'src/app/shared/services/master.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { UserService } from 'src/app/shared/services/user.service';
import { forkJoin, Subscription } from 'rxjs';
import { Avail } from 'src/app/common';
import { ApiService } from 'src/app/shared/services/api.service';
import { environment } from 'src/environments/environment';
import { shareReplay, share } from 'rxjs/operators';
import { DatePipe, LocationStrategy } from '@angular/common';
import * as CryptoJS from 'crypto-js';
import { MenuService } from 'src/app/shared/axaservices/menu.service';
import { LabandPharmacyService } from 'src/app/shared/axaservices/pharmacy.service';
import {DomSanitizer} from '@angular/platform-browser'
import { Console } from 'console';
import { HttpErrorResponse } from '@angular/common/http';


// declare let $: any;
// declare const register: any;
// declare const call: any;
// declare const statusRegister: any;
// declare let fnDecrypt: any;
// declare let fnEncryption: any;
declare let $: any;
declare const register: any;
declare const call: any;
declare const statusRegister: any
declare let fnDecrypt: any;
declare let fnEncryption: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass'],
  providers: [DatePipe]
})

export class LoginComponent implements OnInit, OnDestroy {
  userId: string = '';
  loginForm: FormGroup;  submitted = false; returnUrl: string; error = '';

  name: string = ''; toId: string = ''; fromId: string = ''; userType: number = 1; complaints: string = 'Fever';
  isDoctor: boolean = true;
  isHWC:boolean=false;
   doctor_name: string = ''; doctor_id: string = ''; isRegistered: boolean = false;
  storeData:any;
  logourl = ''; qlfnId = ''; qlfnName = ''; expn = ''; splznName = ''; hwId = ''; hwName = ''; docId = ''; appUrl = ''; roleId = 0;
  ovDtls: any; isActiveMode: boolean = false; isRmbrChk: boolean = false; dataDRHW: any; excRefCode: any; grpRefCode: any; indRefCode: any;
  moduleList: Array<{ id: number, module_name: string, canView: boolean }> = []; screenList: Array<any> = []; permissionList: Array<any> = [];
  tenantData: any; OTP_user: boolean = false; showSessionExpired: boolean = false; unSubscription$: Subscription[] = []; tollFree = '';
  mdlClsBtn = document.getElementById('btnVideoCallModalClose'); appSrce = ''; lgnUrl:any; lgnLogo1 = ''; lgnLogo2 = ''; clientName = '';
  isNIG = !environment.isNIG; pwrdBy = environment.isNIG ? 'eCLINIC234' : 'Dhanush Health Care'; lgnUsrBoxTxt = environment.lgnUsrBoxTxt;
  current_userName: string = '';  
  myId: any;
  gendersList: any;
  superSpecialityDegreeList: any;
  languagesList: any;
  menudetails: any;
  tetantId: string;
  doctorExpryCheck: boolean;
  encryptPswd: any;
  captchaId: string;
  captChaImg: any;
  Flagslist: any;
  showPassword: boolean;
  showPasswordOnPress: boolean;
  organisationImages: any =[];
  webURL: string;
  hwDetails: any;
  enableAyushNEWPOC: any;
  constructor(private toastr: ToastrService
    , private formBuilder: FormBuilder
    , private route: ActivatedRoute,
      private sanitizer:DomSanitizer
    , config: NgbModalConfig
    , private modalService: NgbModal
    , private router: Router
    , private authenticationService: AuthenticationService
    , private masterService: MasterService
    , private registrationService: RegistrationService
    , private notificationService: NotificationService
    , private apiSvc: ApiService
    , private userServices: UserService
    , private dtPipe: DatePipe
    , private menuservice: MenuService,
    
    private locationData: LocationStrategy,
    private pharmaService:LabandPharmacyService
  ) {
    history.pushState(null, null, window.location.href);  
    this.locationData.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });  
    this.appUrl = location.href.includes('localhost') ? Avail.lclUrl : location.origin;
    this.webURL = location.href.includes('localhost') ? environment.applicationURL : location.origin;
    config.backdrop = 'static';
    config.keyboard = false;
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue)
      this.router.navigate(['/']);
  }

  get f() { return this.loginForm.controls; }

  ngOnDestroy(): void {
    this.unSubscription$.forEach(sub => sub.unsubscribe());
  }

  ngOnInit() {
    sessionStorage.removeItem('islogged'), this.isRmbrChk = false;
    this.tetantId = sessionStorage.getItem('currentTenant');
    this.callCaptchaAPI();
    this.authenticationService.appClntSubject.subscribe(s => { this.clientName = s }), this.authenticationService.appSrceSubject.
      subscribe(s => { // this.appSrce
   
        this.appSrce = s;
         
       
        // this.lgnUrl = `${location.origin}${environment.assetsURL}${this.appSrce}${environment.bgLoginNew}`,
       
        //   this.lgnLogo1 = `${location.origin}${environment.assetsURL}${this.appSrce}${environment.lgnLogo1}`,
        //   this.lgnLogo2 = `${location.origin}${environment.assetsURL}${this.appSrce}${environment.lgnLogo2}`
      }), // bgLogin
       //this.authenticationService.tollFreeSubject.subscribe(s => this.tollFree = s),
      this.getOVDtls(), this.loginForm = this.formBuilder.group({ username: ['', Validators.required], password: ['', Validators.required], captchaText: [null, Validators.required] }),
      this.setFocus('username'); // , this.setFocus('password'); // setTimeout(() => { this.setFocus('password'); }, 100); 
    $('input').blur(function () {
      ($(this).val()) ? $(this).addClass('has-content') : $(this).removeClass('has-content');
    });
    // const sub = this.authenticationService.sessionExpired.subscribe(e => this.showSessionExpired = e);
    this.unSubscription$.push(this.authenticationService.sessionExpired.subscribe(e => this.showSessionExpired = e));
    if (this.mdlClsBtn)
      setTimeout(() => { this.mdlClsBtn.click(); }, 1000);
    this.authenticationService.bannerSubject.next([]), this.authenticationService.drugsSubject.next('');
    if (sessionStorage.getItem('lgnSsn') != null) {
      let lgnSsnData = JSON.parse(sessionStorage.getItem('lgnSsn'));
      this.loginForm.get('username').setValue(lgnSsnData.usrName), this.loginForm.get('password').setValue(lgnSsnData.usrPswd), this.isRmbrChk = true;
      $('#username').addClass('has-content'), $('#password').addClass('has-content');
    }
    // setTimeout(() => { alert('eTab Testing') }, 10000);
    $(document).ready(function () {
      var link = document.querySelector("link[rel*='icon']");
      link.setAttribute("type", 'image/x-icon');
      link.setAttribute("rel", 'shortcut icon');
      link.setAttribute("href", `${location.origin}/assets/vmLogos/${environment.webSrce}/appicon.ico`); // link.setAttribute("href", 'http://localhost:4567/assets/vmLogos/swashodhan/appicon.ico');
      document.getElementsByTagName('head')[0].appendChild(link);
     
    });
  }

  setFocus(elementName: string, value: string = '') {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementName)));
    el.value = value;
    el.focus();
  }

  checkValidation(): boolean {
    if ((this.loginForm.get('username').value || '') === '') {
      this.toastr.error('Please enter valid username', 'Login');
      this.setFocus('username');
      return false;
    }
    else if ((this.loginForm.get('password').value || '') === '') {
      this.toastr.error('Please enter valid password', 'Login');
      this.setFocus('password');
      return false;
    }
    else if ((this.loginForm.get('captchaText').value || '') === '') {
      this.toastr.error('Please enter valid Captcha Text', 'Login');
      this.setFocus('captchaText');
      return false;
    }
    return true;
  }

  onChngUN() {
    let uN: string = this.loginForm.get('username').value;
    this.loginForm.get('username').setValue(uN.trim())
  }
  onChngCapthaImg() {
    let cimg: string = this.loginForm.get('captchaText').value;
    this.loginForm.get('captchaText').setValue(cimg.trim())
  }
  closeExistingUser() {
    if (this.userId)
      this.registrationService.session(this.userId).subscribe(res => {
        if (res.status === "success") { this.onSubmit(true); }
      });
    this.userId = '';
  }

  continueLogIn(user: any) {
    sessionStorage.setItem('logUsrName', `${this.loginForm.get('username').value}`);
    sessionStorage.setItem('logUsrRoleId', this.authenticationService.roleId);
    sessionStorage.setItem('userData', JSON.stringify(user));
    this.OTP_user = false;
    if (this.isRmbrChk) {
      let ssnLgnUsr = { usrName: this.loginForm.get('username').value, usrPswd: this.loginForm.get('password').value };
      sessionStorage.setItem('lgnSsn', JSON.stringify(ssnLgnUsr));
    
    }
    else {
      sessionStorage.removeItem('lgnSsn');
     
    }
    this.authenticationService.tnntSbjct.next(user.orgnization_registration.id),
      this.authenticationService.isShowGlblOrg.next(this.authenticationService.roleId == 1); // For superadmin

    if ((this.authenticationService.roleId > 1) && ('123456' === this.loginForm.get('password').value))
      this.OTP_user = true, this.sendOTP('&operation=forgotPassword'), this.openModal();
    else {
      if (this.authenticationService.roleId != 1)
        this.isActiveMode = user.account_activated;
    
      this.getFileTypeMaster();
      if (this.authenticationService.roleId != 1)
        this.getOrgDetails();
      if (this.authenticationService.roleId == 7) {
        this.getDoctorDetails(user.id, user);
      
      }
      if (this.authenticationService.roleId == 9) {
        this.getHWDetails(user.id,user);
      }
      this.authenticationService.updateSession(false);
      if (this.OTP_user)
        return;
     
      if (this.authenticationService.roleId != 7)
        this.loadscreenPermissions();
      if (!this.isActiveMode)
        this.toastr.error('User is in Inactive mode.', 'Login Details');
    }
  }
  getHWDetails(id: any,user: any) {
    // alert('getHWDetails chandu');
    this.registrationService.getHWbyID('health_worker/gethealthworkerrecordbyuserid?userId=', id).subscribe(res => {
      if (res.data.length > 0)
        sessionStorage.setItem('hwDetails', JSON.stringify(res.data[0])),
        this.hwDetails = JSON.parse(sessionStorage.getItem('hwDetails')),
        this.getDrHwDetails(this.authenticationService.roleId, id,user);
     
    }, err => { this.toastr.error(err) });
  }
  getDrHwDetails(roleId: any, usrId: any,user: any) {
    // alert('getDrHwDetails chandu1');
    this.registrationService.getDHDetails(`doctor/getdoctororhealthworkerrecordbyuserid?roleId=${roleId}&userId=${usrId}`).subscribe(res => {
      this.dataDRHW = res.data,
      sessionStorage.setItem('dtlsDrHw', JSON.stringify(this.dataDRHW));
      if(this.hwDetails?.doctor_health_worker[0]?.doctor_registration){
        sessionStorage.setItem('DoctorDetails',JSON.stringify(this.hwDetails?.doctor_health_worker[0]?.doctor_registration));
     
      }else{
        sessionStorage.setItem('DoctorDetails',null);
     
      }
     this.enableAyushNEWPOC?sessionStorage.setItem('pocType','ayushpoc'):sessionStorage.setItem('pocType','ayushpocnew')
      this.ayush_webRTC_method();
     
    },(err:HttpErrorResponse)=>{
      this.toastr.error(err.message)});
  }


  onSubmit(isVerifed = false) {
    this.encryptPswd = fnEncryption(this.loginForm.value.password, environment.securityKey);
    if (this.tenantData)
      sessionStorage.removeItem('isAppURL');
    this.isActiveMode = true, this.submitted = true;
    if (this.checkValidation() == false)
      return false;
    if (this.loginForm.invalid) {
      return;
    }
    else {
      // let tntId = this.tenantData?.id ? this.tenantData.id:null;
      let tntId: any = this.tenantData?.id ?? null;
      //  new lines added for login with captha and encrypted password #chandu 28-04-2022
      this.authenticationService.login(this.loginForm.get('username').value, this.encryptPswd, this.loginForm.get('captchaText').value, this.captchaId, tntId, isVerifed).pipe(share()).
        subscribe({
          next: x => {
            if (x.status == 'success') {
              sessionStorage.setItem('password', this.loginForm.value.password);
              let user = x.response;
              sessionStorage.setItem('loginId', user.id);
              if (!user.account_activated) {
                this.toastr.error('Your login is in Inactive mode', 'Login Details');
                return;
              }
              else if (!user.is_active) {
                this.toastr.error('Your login is Deleted', 'Login Details');
                return;
              }
              else if (!user.jwt) {
                this.current_userName = `${user.first_name} ${user.last_name}`
                this.userId = user.id;
                document.getElementById('btnLogInConfirmMessage')?.click();
                return;
              }
              this.continueLogIn(user);
              this.getUserPermission()

            }
            else {

              this.callCaptchaAPI();
              let errMsg: string = x.message ? x.message : x;
              if (errMsg != '') {
                errMsg = errMsg.toLowerCase();
                if (errMsg.includes("already logged in")) {
                  this.apiSvc.post(`${environment.loginAPI}user/logout?userId=${x.user_id}`, null).
                    subscribe(res => {
                      if (res)
                        this.onSubmit();
                    });
                }
                else
                  this.toastr.error(errMsg);
              }
              else
                this.toastr.error(errMsg);
              this.callCaptchaAPI();
            }
          },
          error: err => {
           this.callCaptchaAPI()
            let errMsg: string = err?.response?.message ?? '';
            if (errMsg != '') {
              errMsg = errMsg.toLowerCase();
              if (errMsg.includes("already logged in")) {
                this.apiSvc.post(this.appUrl + 'user/logout', { "login_name": this.loginForm.get('username').value }).
                  subscribe(res => {
                    if (res)
                      this.onSubmit();
                  });
              }
              else{
                //this.toastr.error(errMsg);
              }
               
            }
            else{
              //this.toastr.error(errMsg);
            }
              
          }

        });
    }

  }
  getUserPermission() {

    let tntId: any = this.tenantData?.id ?? null;
    this.userServices.getUserPermissionByOrgId(tntId).subscribe((res: any) => {
      if (res.status == 'OK') {
        this.Flagslist = res.data;
        localStorage.setItem('featuresList', JSON.stringify(this.Flagslist))
        for (let i = 0; i < res.data.length; i++) {
          // if (res.data[i].feature_id.feature_name == "doctorPocfilter") {
          //   this.pocpatientHistory = res.data[i].patient
          // }
          if (res.data[i].feature_id.feature_name == "ayushNewPOC") {
            this.enableAyushNEWPOC = res.data[i].patient;

          }
         
          //isEnableCovid19
          // if (res.data[i].feature_id.feature_name == "hideOtherDrugLabelinPOC") {
          //   if (res.data[i].patient) {
          //     this.hideOtherDrugLabel = true;
          //   } else {
          //     this.hideOtherDrugLabel = false;
          //   }

          
        }
        
      }
    });
  }
  getDoctorDetails(id: any, user: any) {
    this.registrationService.getByID('doctor/getdoctorrecordbyuserid?userId=', id).subscribe(res => {
      if (res.data.length > 0) {
        var pocType = res.data[0].specialization_id;
         if(pocType==198){
          this.enableAyushNEWPOC?sessionStorage.setItem('pocType','ayushpoc'):sessionStorage.setItem('pocType','ayushpocnew')
         }
         else{
          sessionStorage.setItem('pocType','poc')
         }
        sessionStorage.setItem('DoctorDetails', JSON.stringify(res.data[0]));
        //this.getDrHwDetails(this.authenticationService.roleId, id,user);
        localStorage.setItem('DoctorDetails', JSON.stringify(res.data[0]));
        if (res.data[0].center_type === 'Center' && this.authenticationService.roleId == 7) {
          this.toastr.info("You are not Authorised to login");
          this.authenticationService.logout();
          return;
        }
        if (res.data[0].center_type === 'Referral' || res.data[0].is_referral) {
          sessionStorage.setItem('showRefScreen', 'true');
        }
        this.masterService.getAll(`getqualificationbyid?qualificationId=${res.data[0].qualification_id}`).subscribe(qfcn => {
          [this.doctor_id, this.doctor_name, this.qlfnId, this.expn, this.qlfnName, this.grpRefCode, this.indRefCode, this.excRefCode] =
            [res.data[0].id, user?.first_name + ' ' + user?.last_name, res.data[0].qualification_id, res.data[0].years_experience,
            qfcn.data == null ? 'MBBS' : qfcn.data.qualification, res.data[0].group_reference_code, res.data[0].individual_reference_code,
            res.data[0].exclusive_reference_code, res.data[0].hub_id, res.data[0].center_id, res.data[0].center_type];
          let deDt = +this.dtPipe.transform(res.data[0]?.reg_expiry_date, "yyyyMMdd"), tdDt = +this.dtPipe.transform((new Date()), "yyyyMMdd");
          if (deDt < tdDt) {
            this.doctorExpryCheck == true
            document.getElementById('drRegExpiry')?.click();
          }

          else {
            if (this.isActiveMode)
              this.webRTC_method();
          }
        });
      }
    },(err:HttpErrorResponse)=>{
  this.toastr.error(err.message)});
  }

  getOrgDetails() {
    let id = this.authenticationService.currentUserValue.orgnization_registration?.id ?? '0';
    sessionStorage.setItem('TENANT_ID', id);
    if (id != '')
      this.userServices.getById(`organization/getorganizationsettingbyorgid?orgId=${+id}`).subscribe(res => {
        let response = res.data;
        sessionStorage.setItem('OrgDetails', JSON.stringify(response));
      }, err => { this.toastr.error(err.message) });
  }

  getFileTypeMaster() {
    this.masterService.getAll('getallfiletypes').subscribe(res => {
      let data = res.data;
      sessionStorage.setItem('FileTypeMaster', JSON.stringify(data));
    },
      err => { this.toastr.error(err) });
  }

  ayush_webRTC_method() {
    // alert(' ayush_webRTC_method');
     [this.toId, this.fromId, this.name, this.userType, this.complaints, this.myId] = ['', `${this.dataDRHW?.fromId}`, this.dataDRHW?.name,1, '',this.dataDRHW?.doctorId];
    let orgData = JSON.parse(sessionStorage.getItem('userData'));
    this.isRegistered = true;
    let usrTkn = `org_${orgData.orgnization_registration.id}_${orgData.orgnization_registration.orgnization_name}_${this.fromId}_${this.name}_${(new Date()).getTime()}`.replace(/ /g, '_');
    (<HTMLInputElement>document.getElementById('sessionName')).value = this.fromId;
    (<HTMLInputElement>document.getElementById('fromId')).value = this.fromId;
    (<HTMLInputElement>document.getElementById('toId')).value = this.toId;
    (<HTMLInputElement>document.getElementById('newUserId')).value = usrTkn;
    // (<HTMLInputElement>document.getElementById('fromId')).value = this.fromId;
    // (<HTMLInputElement>document.getElementById('toId')).value = this.toId;
  this.dataDRHW = JSON.parse(sessionStorage.getItem('dtlsDrHw'));
  
  var qualification_id = this.hwDetails?.qualification_id,
  years_experience =this.hwDetails?.years_experience,
  qualification=this.dataDRHW?.qualification,
  groupReferenceCode=this.dataDRHW?.groupReferenceCode,
  individualReferenceCode=this.dataDRHW?.individualReferenceCode,
  healthWorkerId=this.dataDRHW?.healthWorkerId,
  healthWorkers=this.dataDRHW?.name
    if (+this.userType == 1) {
      // alert('User Type'+this.userType);
      this.complaints = '';
      var gender =this.dataDRHW?.gender;;
      var super_Qualification = '';
      var doctor_languages_name =this.dataDRHW?.languages
      var document_path = this.dataDRHW?.doctorPhoto;
      let logUsr = {
        tkn: usrTkn, toId: this.toId, fromId: this.fromId, name: this.name, userType: this.userType==1?'healthworker':'healthworker',complaints: this.complaints, myId: this.myId,
        qlfn:qualification_id, expn: years_experience, qlfnName:qualification, grCode: groupReferenceCode, irCode: individualReferenceCode, erCode: '',healthWorkerId:healthWorkerId,healthWorkers:healthWorkers
      };
  
        sessionStorage.setItem('webSkt', JSON.stringify(logUsr));
        sessionStorage.setItem('islogged', '1');
        sessionStorage.setItem('gender', gender);
        localStorage.setItem('gender', gender);
        localStorage.setItem('super_Qualification', super_Qualification);
        localStorage.setItem('doctor_languages_name',doctor_languages_name);
        localStorage.setItem('document_path_new', document_path);
        sessionStorage.setItem('super_Qualification', super_Qualification);
        sessionStorage.setItem('doctor_languages_name', doctor_languages_name);
        sessionStorage.setItem('document_path_new', document_path);
        register(this.toId, this.fromId, this.name, this.userType==1?'healthworker':'healthworker', this.complaints, this.myId, this.qlfnId, this.expn);
        // below lines commented for new hubid centerid centertype added in req  19-04-2022 #Chandu
        // statusRegister(this.qlfnId, this.expn, this.qlfnName, this.grpRefCode, this.indRefCode, this.excRefCode); docData.specialization_name
        let hwDetails =JSON.parse(sessionStorage.getItem('hwDetails'));
        // console.log('Health worker details',JSON.stringify(hwDetails));
        // alert('Health worker details check');
        statusRegister(qualification_id, years_experience,qualification, groupReferenceCode, individualReferenceCode, '', hwDetails?.doctor_health_worker[0]?.doctor_registration?.hub_id,hwDetails?.doctor_health_worker[0]?.doctor_registration?.hub_name,hwDetails?.doctor_health_worker[0]?.doctor_registration?.center_id,hwDetails?.doctor_health_worker[0]?.doctor_registration?.center_type,hwDetails?.doctor_health_worker[0]?.doctor_registration?.specialization_name, localStorage.getItem('gender'),  this.dataDRHW?.numberOfYrsExperience, localStorage.getItem('super_Qualification'), this.dataDRHW?.department_name, localStorage.getItem('doctor_languages_name'), localStorage.getItem('document_path'),healthWorkerId,healthWorkers);
      
        //register(this.toId, this.fromId, this.name, this.userType, this.complaints, this.myId);
     
    }
    else {
      this.complaints = 'Fever';
      register(this.toId, this.fromId, this.name, this.userType, this.complaints, this.myId);
      setTimeout(() => this.callToDoctor(), 1000);
    }
  }

  webRTC_method() {
    // alert('webRTC_method');
    let orgData = JSON.parse(sessionStorage.getItem('userData'));
     if (this.isDoctor)
      [this.toId, this.fromId, this.name, this.userType, this.complaints, this.myId] = ['', `doc_${this.doctor_id}`, this.doctor_name, 1, '', this.doctor_id];

      // if (this.isHWC){
      //   [this.toId, this.fromId, this.name, this.userType, this.complaints, this.myId] = ['', `doc_${this.doctor_id}`, this.doctor_name, 1, '', this.doctor_id];
      //   [this.toId, this.fromId, this.name, this.userType, this.complaints, this.myId] = ['', this.dataDRHW.fromId, this.dataDRHW.name, 1, '', true];
      // }
 
     
    else
      [this.toId, this.fromId, this.name, this.userType, this.complaints, this.myId] = ['doc_100', 'pat_100', 'Uma Mahesh', 0, 'Fever', '100'];

    this.isRegistered = true;
    let usrTkn = `org_${orgData.orgnization_registration.id}_${orgData.orgnization_registration.orgnization_name}_${this.fromId}_${this.name}_${(new Date()).getTime()}`.replace(/ /g, '_');
    (<HTMLInputElement>document.getElementById('sessionName')).value = this.fromId;
    (<HTMLInputElement>document.getElementById('fromId')).value = this.fromId;
    (<HTMLInputElement>document.getElementById('toId')).value = this.toId;
    (<HTMLInputElement>document.getElementById('newUserId')).value = usrTkn;
    (<HTMLInputElement>document.getElementById('doctor_id')).value = usrTkn;
    //doctor_id
    var docData = JSON.parse(sessionStorage.getItem('DoctorDetails'))
    docData = JSON.parse(localStorage.getItem('DoctorDetails'))

    var healthWorkerId=docData?.doctor_health_worker[0]?.id;
    var healthWorkers=docData?.doctor_health_worker[0]?.first_name+ ' '+docData?.doctor_health_worker[0]?.last_name

    if (+this.userType === 1) {
      this.complaints = '';
      let logUsr = {
        tkn: usrTkn, toId: this.toId, fromId: this.fromId, name: this.name, userType: this.userType, complaints: this.complaints, myId: this.myId,
        qlfn: this.qlfnId, expn: this.expn, qlfnName: this.qlfnName, grCode: this.grpRefCode, irCode: this.indRefCode, erCode: this.excRefCode,healthWorkerId:healthWorkerId,healthWorkers:healthWorkers
      };
      // let docData = this.isDoctor?JSON.parse(sessionStorage.getItem('DoctorDetails')): JSON.parse(sessionStorage.getItem('hwDetails'));
      // docData = this.isDoctor?JSON.parse(localStorage.getItem('DoctorDetails')):JSON.parse(sessionStorage.getItem('hwDetails'));;

      if (docData?.center_type == 'Referral') {
        localStorage.setItem('isReferral', 'Referral')
      }
      sessionStorage.setItem('webSkt', 
      JSON.stringify(logUsr)), sessionStorage.setItem('islogged', '1');
      const genders = this.masterService.getAll('getallgenders');
      const superSpecialityDegree = this.masterService.getAll('getallsuperspecialityqualifications');
      const languages = this.masterService.getAll('getalllanguages');
      var gender;
      var super_Qualification = '';
      var doctor_languages_name = '';
      var document_path = '';
      forkJoin([genders, superSpecialityDegree, languages]).subscribe({
        next: res => {
          res.forEach((list, ind) => {
            try {
              if (list.status === 'OK') {
                if (list.data == null || list.data.length == 0)
                  console.log('No records available');
                else {
                  const assignList = {
                    '0': () => this.gendersList = list.data,
                    '1': () => this.superSpecialityDegreeList = list.data,
                    '2': () => this.languagesList = list.data
                  }
                  assignList[ind]();
                }
                gender = this.getGender(docData.gender_id);
                super_Qualification = this.getSuperQualification(docData.super_qualification_id);
         
                for (let i = 0; i < this.languagesList?.length; i++) {
                  for (let j = 0; j < docData.doctor_languages.length; j++) {
                    if (this.languagesList[i].id == docData.doctor_languages[j].language_id) {
                      if (doctor_languages_name && doctor_languages_name != '') {
                        doctor_languages_name = doctor_languages_name + ',' + this.languagesList[i].language_name;
                      } else {
                        doctor_languages_name = this.languagesList[i].language_name;
                      }

                    }

                  }
                }
                for (let i = 0; i < docData.doctor_documents.length; i++) {
                  if (docData.doctor_documents[i].file_type_id == 31) {
                    document_path = docData.doctor_documents[i].document_path;
                  } else if (docData.doctor_documents[i].file_type_id == 5) {
                    document_path = docData.doctor_documents[i].document_path;
                  }
                }
               
              //  if(this.isHWC){
              //   for (let i = 0; i < this.languagesList?.length; i++) {
              //     for (let j = 0; j < docData.health_worker_languages.length; j++) {
              //       if (this.languagesList[i].id == docData.health_worker_languages[j].language_id) {
              //         if (doctor_languages_name && doctor_languages_name != '') {
              //           doctor_languages_name = doctor_languages_name + ',' + this.languagesList[i].language_name;
              //         } else {
              //           doctor_languages_name = this.languagesList[i].language_name;
              //         }

              //       }

              //     }
              //   }
              //   for (let i = 0; i < docData.health_worker_documents.length; i++) {
              //     if (docData.health_worker_documents[i].file_type_id == 31) {
              //       document_path = docData.health_worker_documents[i].document_path;
              //     } else if (docData.health_worker_documents[i].file_type_id == 5) {
              //       document_path = docData.health_worker_documents[i].document_path;
              //     }
              //   }
              //  }
              //  console.log('HWDATA',docData)
                sessionStorage.setItem('gender', gender);
                localStorage.setItem('gender', gender);
                localStorage.setItem('super_Qualification', super_Qualification);
                localStorage.setItem('doctor_languages_name', doctor_languages_name);
                localStorage.setItem('document_path_new', document_path);
                sessionStorage.setItem('super_Qualification', super_Qualification);
                sessionStorage.setItem('doctor_languages_name', doctor_languages_name);
                sessionStorage.setItem('document_path_new', document_path);
                register(this.toId, this.fromId, this.name, this.userType, this.complaints, this.myId, this.qlfnId, this.expn);
                // below lines commented for new hubid centerid centertype added in req  19-04-2022 #Chandu
                // statusRegister(this.qlfnId, this.expn, this.qlfnName, this.grpRefCode, this.indRefCode, this.excRefCode); docData.specialization_name
                statusRegister(this.qlfnId, this.expn, this.qlfnName, this.grpRefCode, this.indRefCode, this.excRefCode, docData?.hub_id, docData?.center_id, docData?.center_type, docData?.specialization_name, localStorage.getItem('gender'), docData?.years_experience, localStorage.getItem('super_Qualification'), docData?.department_name, localStorage.getItem('doctor_languages_name'), localStorage.getItem('document_path'),healthWorkerId,healthWorkers);

                if (this.isActiveMode) {
                  this.loadscreenPermissions();
                }

              }
            } catch (e) {
              console.log(e);
            }

          });
        },
        error: err => console.error('something wrong occurred: ', err),
        complete: () => { }
      });

    }
    else {
      this.complaints = 'Fever';
      register(this.toId, this.fromId, this.name, this.userType, this.complaints, this.myId);
      setTimeout(() => this.callToDoctor(), 1000);
    }
  }
  getGender(id: string): string {
    let result = this.gendersList.find(c => c.id == id);
    return result ? result.gender_name : '';
  }
  getSuperQualification(id: string): string {
    let result = this.superSpecialityDegreeList?.find(c => c.id == id);
    return (result) ? result.qualification : '';
  }
  
  callToDoctor() {
    call(this.toId, this.fromId, this.name, this.complaints);
    this.router.navigate([`home/${sessionStorage.getItem('pocType')}`])
      .then((e) => {
        if (e) {
          // answerCall(fromId, toId);
          console.log("Navigation is successful!");
        } else {
          console.log("Navigation has failed!");
        }
      });
  }
  callCaptchaAPI() {
    let respData;
    this.apiSvc.get(`vidmed-login/captcha/captchaImg`).
      subscribe(res => {
        if (res) {
          respData = res['data'];
          this.captchaId = respData.captchaId;
          this.captChaImg = 'data:image/png;base64,' + respData.captchaImg;
        }
      });

  }
  sendOTP(oprtn: string = '') {
    if (this.tenantData)
      sessionStorage.removeItem('isAppURL'), this.notificationService.send_OTP_Notification(`email=${this.loginForm.get('username').value}&TENANT_ID=${this.tenantData?.id}${oprtn}`).subscribe();
    else
      this.toastr.error('No tenant details found', 'kindly Contact Admin');
  }

  openModal(type: number = 1) {
    const modalRef = this.modalService.open(VerifyOTPComponent);
    modalRef.componentInstance.title = 'OTP Verification';
    modalRef.componentInstance.email_id = this.loginForm.get('username').value;
    modalRef.result.then(result => {
      (type == 1) ? this.openModal_ChanePassword() : this.openModal_forgotPassword();
    }, (reason) => { });
  }

  openModal_ChanePassword() {
    const modalRef = this.modalService.open(ChangePasswordComponent);
    modalRef.componentInstance.title = 'Change Password';
    modalRef.result.then(result => {

    }, (reason) => { });
  }

  openModal_forgotPassword() {
    if (this.tenantData) {
      const modalRef = this.modalService.open(ForgotPasswordComponent);
      modalRef.componentInstance.title = 'Forgot Password';
      modalRef.componentInstance.userId = this.loginForm.get('username').value;
      modalRef.componentInstance.tenantId = this.tenantData ? this.tenantData.id.toString() : '';
      modalRef.result.then(result => { }, (reason) => { });
    }
    else
      this.toastr.error('No tenant details found', 'kindly Contact Admin');
  }

  forgotpaasword() {
    if ((this.loginForm.get('username').value || '') === '') {
      this.toastr.error('Please enter valid username', 'Login');
      this.setFocus('username');
      return false;
    }
    else
      this.sendOTP('&operation=forgotPassword'), this.openModal(2);
  }

  loadModules() {
    this.moduleList = [], this.screenList.forEach(item => {
      let isExists = this.moduleList.find(({ id }) => id == item.module.id);
      let perm = this.permissionList.find(p => p.screenId == item.id);
      [item.can_delete, item.can_insert, item.can_update, item.can_view] =
        perm ? [perm.canDelete, perm.canInsert, perm.canUpdate, perm.canView] : [false, false, false, false];
      if (isExists == null)
        this.moduleList.push({ id: item.module.id, module_name: item.module.module_name, canView: false });
    });
    //Module can enable or disable
    this.moduleList.forEach(m => {
      let arr = this.screenList?.filter(s => m.id == s.module.id);
      if (arr)
        m.canView = arr.some(a => a.can_view == true);
    });
    sessionStorage.setItem('modules', btoa(JSON.stringify(this.moduleList)));
    sessionStorage.setItem('screen', btoa(JSON.stringify(this.screenList)));
    


    if (this.authenticationService?.roleId == 1)       // Super Admin
    {
      
      this.menuservice.loadPermissions().subscribe(res => {
        if (this.doctorExpryCheck == true) {
        }
        this.menudetails = res;
        let module: any;
        module = this.menudetails?.filter(m => m.moduleId == 4);
        // let subMenu: any = module[0]?.['subMenu'];
        // const menu = subMenu?.filter(res => res.screen_id == 85);
        // if(module && menu){
        //   sessionStorage.setItem('menu', JSON.stringify(menu?.[0]));
        //   this.router.navigate(['home/doctorReg/doctorlist']);
        //   localStorage.setItem('mId', '4')
        // }
        // else{
        //   this.router.navigate(['home']);
        // }

        if(module && module.length>0){
          let subMenu: any = module[0]['subMenu'];
            const menu = subMenu?.filter(res => res.screen_name == 'doctorlist');
            if(menu && menu.length>0){
            sessionStorage.setItem('menu', JSON.stringify(menu[0]));
            this.router.navigate(['home/doctorReg/doctorlist']);
            localStorage.setItem('mId', '4')
          }
         else{
          this.router.navigate(['home']);
          localStorage.setItem('mId', '4')
         }
           

        }
       
      else{
      this.router.navigate(['home']);
           }
      
    
      });
      
    }

    else if (this.authenticationService.roleId == 2) {// Admin
      

      //alert('11111')
      if (sessionStorage.getItem('TENANT_ID') != null)
        this.SetPlanNamebyOrgId(sessionStorage.getItem('TENANT_ID'));
      //this.router.navigate(['home/docReg/doctorlist']);
      

    }
    else if (this.authenticationService.roleId == 7)  // Doctor
    {
      this.menuservice.loadPermissions().subscribe(res => {
        this.menudetails = res;
        let module: any;
        module = this.menudetails?.filter(m => m.moduleId == 6);
        if(module && module.length>0){
          let subMenu: any = module[0]['subMenu'];
            const menu = subMenu?.filter(res => res.screen_name == 'cn');
            if(menu && menu.length>0){
            sessionStorage.setItem('menu', JSON.stringify(menu[0]));
            this.router.navigate(['home/consultation/cn']);
            localStorage.setItem('mId', '6')
          }
         else{
          this.router.navigate(['home']);
          localStorage.setItem('mId', '6')
         }
           

        }
       
      else{
  this.router.navigate(['home']);
           }
      
   
      });
      


    }else if(this.authenticationService.roleId == 9){
      this.menuservice.loadPermissions().subscribe(res => {
        this.menudetails = res;
        let module: any;
        module = this.menudetails?.filter(m => m.moduleId == 6);
        if(module && module.length>0){
          let subMenu: any = module[0]['subMenu'];
            const menu = subMenu?.filter(res => res.screen_name == 'consultHW');
            if(menu && menu.length>0){
            sessionStorage.setItem('menu', JSON.stringify(menu[0]));
            this.router.navigate(['home/consultation/consultHW']);
            localStorage.setItem('mId', '6')
          }
         else{
          this.router.navigate(['home']);
          localStorage.setItem('mId', '6')
         }
           

        }
       
      else{
  this.router.navigate(['home']);
           }
      
   
      });
    }

    

    else if (this.authenticationService.roleId == 3) {
      
      this.menuservice.loadPermissions().subscribe(res => {
        this.menudetails = res;
        let module: any;
        module = this.menudetails?.filter(m => m.moduleId == 8);
        // let subMenu: any = module[0]['subMenu'];
        // const menu = subMenu?.filter(res => res.screen_id == 66);
  
        // if(module && menu){
        //   sessionStorage.setItem('menu', JSON.stringify(menu[0]));
        //   this.router.navigate(['/home/crpt/crpemp']);
        //   localStorage.setItem('mId', '8')
        // }
        // else{
        //   this.router.navigate(['home']);
        // }
        if(module && module.length>0){
          let subMenu: any = module[0]['subMenu'];
            const menu = subMenu?.filter(res => res.screen_name == 'crpemp');
            if(menu && menu.length>0){
            sessionStorage.setItem('menu', JSON.stringify(menu[0]));
            this.router.navigate(['/home/crpt/crpemp']);
            localStorage.setItem('mId', '8')
          }
         else{
          this.router.navigate(['home']);
          localStorage.setItem('mId', '8')
         }
           

        }
       
      else{
      this.router.navigate(['home']);
           }
        

      });
      
    } // Corporate User

    else if (this.authenticationService.roleId == 35) // Axa Admin
    {
      
      //this.router.navigate(['/home/registrations/doclist'])
      this.menuservice.loadPermissions().subscribe(res => {
        this.menudetails = res;
        let module: any;
        module = this.menudetails?.filter(m => m.moduleId == 4);
        // let subMenu: any = module[0]['subMenu'];
        // const menu = subMenu?.filter(res => res.screen_id == 84);
      
        // if(module && menu){
        //   sessionStorage.setItem('menu', JSON.stringify(menu[0]));
        //   this.router.navigate(['/home/crpt/corporate'])
        //   localStorage.setItem('mId', '4')
        // }
        //  else{
        //   this.router.navigate(['home'])
        //  }
        if(module && module.length>0){
          let subMenu: any = module[0]['subMenu'];
            const menu = subMenu?.filter(res => res.screen_name == 'corporate');
            if(menu && menu.length>0){
            sessionStorage.setItem('menu', JSON.stringify(menu[0]));
            this.router.navigate(['/home/crpt/corporate'])
            localStorage.setItem('mId', '4')
          }
         else{
          this.router.navigate(['home']);
          localStorage.setItem('mId', '4')
         }
           

        }
       
      else{
      this.router.navigate(['home']);
           }


      });

      
    }
    else if (this.authenticationService.roleId == 31) // Axa Admin
    {
      
      //this.router.navigate(['/home/registrations/doclist'])
      this.menuservice.loadPermissions().subscribe(res => {
        this.menudetails = res;
        let module: any;
        module = this.menudetails?.filter(m => m.moduleId == 4);
        if(module && module.length>0){
          let subMenu: any = module[0]['subMenu'];
            const menu = subMenu?.filter(res => res.screen_name == 'corporate');
            if(menu && menu.length>0){
            sessionStorage.setItem('menu', JSON.stringify(menu[0]));
            this.router.navigate(['/home/crpt/corporate'])
            localStorage.setItem('mId', '4')
          }
         else{
          this.router.navigate(['home']);
          localStorage.setItem('mId', '4')
         }
           

        }
       
      else{
      this.router.navigate(['home']);
           }

      });

      
    }
    else if (this.authenticationService.roleId == 33) // TPA Admin
    {
      

      this.menuservice.loadPermissions().subscribe(res => {
        this.menudetails = res;
        let module: any;
        module = this.menudetails?.filter(m => m.moduleId == 4);
        if(module && module.length>0){
          let subMenu: any = module[0]['subMenu'];
            const menu = subMenu?.filter(res => res.screen_name == 'corporate');
            if(menu && menu.length>0){
            sessionStorage.setItem('menu', JSON.stringify(menu[0]));
            this.router.navigate(['/home/crpt/corporate'])
            localStorage.setItem('mId', '4')
          }
         else{
          this.router.navigate(['home']);
          localStorage.setItem('mId', '4')
         }
           

        }
       
      else{
      this.router.navigate(['home']);
           }      });
      
    }
    else if (this.authenticationService.roleId == 34) // TPA USER
    {
      
      this.menuservice.loadPermissions().subscribe(res => {
        this.menudetails = res;
        let module: any;
        module = this.menudetails?.filter(m => m.moduleId == 4);
        if(module && module.length>0){
          let subMenu: any = module[0]['subMenu'];
            const menu = subMenu?.filter(res => res.screen_name == 'corporate');
            if(menu && menu.length>0){
            sessionStorage.setItem('menu', JSON.stringify(menu[0]));
            this.router.navigate(['/home/crpt/corporate'])
            localStorage.setItem('mId', '4')
          }
         else{
          this.router.navigate(['home']);
          localStorage.setItem('mId', '4')
         }
           

        }
       
      else{
      this.router.navigate(['home']);
           }
      });
      
    }
    else if (this.authenticationService.roleId == 5) // Lab User
    {
      
      this.menuservice.loadPermissions().subscribe(res => {
        this.menudetails = res;
        let module: any;
        module = this.menudetails?.filter(m => m.moduleId == 13);
        // let subMenu: any = module[0]?.['subMenu'];
        // const menu = subMenu?.filter(res => res.screen_id == 107);
        // if(module && menu){
        //   sessionStorage.setItem('menu', JSON.stringify(menu[0]));
        //   this.router.navigate(['home/lab/labsample']);
        //   localStorage.setItem('mId', '13');
        // }
        // else{
        //   this.router.navigate(['home']);
        // }
        if(module && module.length>0){
          let subMenu: any = module[0]['subMenu'];
            const menu = subMenu?.filter(res => res.screen_name == 'labsample');
            if(menu && menu.length>0){
            sessionStorage.setItem('menu', JSON.stringify(menu[0]));
            this.router.navigate(['home/lab/labsample']);
            localStorage.setItem('mId', '13')
          }
         else{
          this.router.navigate(['home']);
          localStorage.setItem('mId', '13')
         }
           

        }
       
      else{
      this.router.navigate(['home']);
           }
      });
      
    }

    else if (this.authenticationService.roleId == 4) // Phar User
    {
      
      this.menuservice.loadPermissions().subscribe(res => {
        this.menudetails = res;
        let module: any;
        module = this.menudetails?.filter(m => m.moduleId == 12);
        // let subMenu: any = module[0]['subMenu'];
        // const menu = subMenu?.filter(res => res.screen_id == 104);
      
        // if(module && menu){
        //   sessionStorage.setItem('menu', JSON.stringify(menu[0]));
        //   this.router.navigate(['/home/pharma/lplist']);
        //   localStorage.setItem('mId', '12');
        // }
        // else{
        //   this.router.navigate(['home']);
          
        // }
        if(module && module.length>0){
          let subMenu: any = module[0]['subMenu'];
            const menu = subMenu?.filter(res => res.screen_name == 'lplist');
            if(menu && menu.length>0){
            sessionStorage.setItem('menu', JSON.stringify(menu[0]));
            this.router.navigate(['/home/pharma/lplist']);
            localStorage.setItem('mId', '12')
          }
         else{
          this.router.navigate(['home']);
          localStorage.setItem('mId', '12')
         }
           

        }
       
      else{
      this.router.navigate(['home']);
           }

      });
      
    }


    else if (this.authenticationService.roleId == 14){
      
      this.menuservice.loadPermissions().subscribe(res => {
        this.menudetails = res;
        let module: any;
        module = this.menudetails?.filter(m => m.moduleId == 6);
//         let subMenu: any = module[0]['subMenu'];
//         const menu = subMenu?.filter(res => res.screen_id == 124);
     
//         if(module && menu){
//           sessionStorage.setItem('menu', JSON.stringify(menu[0]));
//           this.router.navigate(['/home/consultation/Hasession']);
//          localStorage.setItem('mId', '6')
//         }
//   else{
//   this.router.navigate(['home']);
// }
if(module && module.length>0){
  let subMenu: any = module[0]['subMenu'];
    const menu = subMenu?.filter(res => res.screen_name == 'Hasession');
    if(menu && menu.length>0){
    sessionStorage.setItem('menu', JSON.stringify(menu[0]));
    this.router.navigate(['/home/consultation/Hasession']);
    localStorage.setItem('mId', '6')
  }
 else{
  this.router.navigate(['home']);
  localStorage.setItem('mId', '6')
 }
   

}

else{
this.router.navigate(['home']);
   }


      });
      

      //this.router.navigate(['home/agentlistcall']);
    }  // Agent
     
    else
      this.router.navigate(['home']);
  }

  SetPlanNamebyOrgId(orgId: any) {
    this.userServices.getById(`organization/orgSettings/${orgId}`).subscribe(res => {
      if (res) {
        if (res.data?.license_plan_id != null) {
          let plan = res.data?.license_plan_id ?? null;
          if (plan != null)
            sessionStorage.setItem('planLcns', plan?.license_plan ?? '');
        }
      }
      this.router.navigate(['home/consultation/appointment']); // home/roleList
    });
  }

  loadscreenPermissions() {
    this.menuservice.loadPermissions().subscribe(res => {
      this.permissionList = res;
    });
    this.loadModules();

  }


  async loadPermissions() {
    // const screens = await this.userServices.getAll('screen/getall');
    // const permissions = await this.userServices.getById(`userrolescreenpermission/get_by_roleid?role_id=${this.authenticationService.roleId}`);
    // const permissions = await this.userServices.getById(`userrolescreenpermission/get-by-roleid/${this.authenticationService.roleId}`);
    const permissions = await this.userServices.getById(`userrolescreenpermission/get-menus/${this.authenticationService.roleId}/${this.tetantId}`);
    //console.log(this.authenticationService.roleId)
    forkJoin([permissions]).subscribe({
      next: res => {
        res.forEach((list, ind) => {
          if (list.status === 'OK') {
            if (list.data == null || list.data.length == 0)
              console.log('No records available');
            else
              this.screenList = list.data;
          }
          else if (list.status === 'success')
            this.permissionList = list.response;
        });
      },
      error: error => console.log(error.message),
      complete: () => { this.loadModules(); }
    })
  }

  encrypt(params, secretKey) {
    // key = CryptoJS.enc.Utf8.parse(key);
    // const i = CryptoJS.enc.Utf8.parse('1583288699248111');
    // const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(value), key, {iv: i}).toString();
    // //console.log('SS cipherText: ' + ciphertext);
    // document.getElementById("ct").innerHTML = 'SS cipherText: ' + ciphertext;
    // return ciphertext;
    const plaintext = params;
    const pwhash = CryptoJS.SHA1(CryptoJS.enc.Utf8.parse(secretKey));
    const key = CryptoJS.enc.Hex.parse(pwhash.toString(CryptoJS.enc.Hex).substr(0, 32));
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 8; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    let encrPwd = text + params;
    var encrypted = CryptoJS.AES.encrypt(encrPwd, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });

    var ciphertext = encrypted.ciphertext.toString(CryptoJS.enc.Hex);
    // $('#result').text(ciphertext);
    // console.log(ciphertext);
    return ciphertext;
  }

  decrypt(params, secretKey) {
    // key = CryptoJS.enc.Utf8.parse(key);
    // const i = CryptoJS.enc.Utf8.parse('1583288699248111');
    // const decryptedData = CryptoJS.AES.decrypt(value, key, {iv: i}); // Fix: pass Base64 encoded ciphertext
    // //console.log('SS utf8 decryptedData: ' + decryptedData.toString(CryptoJS.enc.Utf8));
    // document.getElementById("dt").innerHTML = 'SS utf8 decryptedData: ' + decryptedData.toString(CryptoJS.enc.Utf8);
    // return decryptedData.toString(CryptoJS.enc.Utf8);
    const ciphertext = CryptoJS.enc.Hex.parse(params);
    const pwhash = CryptoJS.SHA1(CryptoJS.enc.Utf8.parse(secretKey));
    const key = CryptoJS.enc.Hex.parse(pwhash.toString(CryptoJS.enc.Hex).substr(0, 32));
    const decrypted = CryptoJS.AES.decrypt(params, secretKey, { iv: key }); // Fix: pass Base64 encoded ciphertext

    // const decrypted = CryptoJS.AES.decrypt({
    //   ciphertext: ciphertext
    // }, key, {
    //   mode: CryptoJS.mode.ECB,
    //   padding: CryptoJS.pad.Pkcs7
    // });

    const plaintext = decrypted.toString(CryptoJS.enc.Utf8);

    // $('#result').text(plaintext);
    return plaintext.substring(8, plaintext.length);
  }
  //NEW OPENVIDU URL NAVIOGATION 14-03-2023
  // getOVDtls() {
  //   sessionStorage.removeItem('isAppURL'), this.userServices.getOpenViduDtls(1).toPromise().then((ov: any) => {
  //     let ovDtls = ov.data[0]; sessionStorage.setItem('ovDtls', JSON.stringify(ovDtls)),
  //     (<HTMLInputElement>document.getElementById('ovUrl')).value = 'https://vmedproav.dhanushinfotech.com/openvidu',
  //       // (<HTMLInputElement>document.getElementById('ovUrl')).value = ovDtls.openvidu_url,
  //       (<HTMLInputElement>document.getElementById('ovKey')).value = ovDtls.secret_key,
  //       (<HTMLInputElement>document.getElementById('apUrl')).value = this.userServices.appUrl;
  //     (<any>window).OPENVIDU_SERVER_URL = 'https://vmedproav.dhanushinfotech.com/openvidu';
  //     (<any>window).OPENVIDU_SERVER_SECRET = ovDtls.secret_key;
  //     // (<any>window).OPENVIDU_SERVER_SECRET = ovDtls.secret_key;
  //     (<any>window).vidmed_url = `${this.userServices.appUrl}telecon/`;
  //     if (this.mdlClsBtn)
  //       setTimeout(() => { this.mdlClsBtn.click(); }, 1000);
  //     this.getTenantDetails();
    
  //   });
  // }
  
  getOVDtls() {
    sessionStorage.removeItem('isAppURL'), this.userServices.getOpenViduDtls(1).toPromise().then((ov: any) => {
      let ovDtls = ov.data[0]; sessionStorage.setItem('ovDtls', JSON.stringify(ovDtls)),
      (<HTMLInputElement>document.getElementById('ovUrl')).value = ovDtls.openvidu_url+'/openvidu',
        // (<HTMLInputElement>document.getElementById('ovUrl')).value = ovDtls.openvidu_url,
        (<HTMLInputElement>document.getElementById('ovKey')).value = ovDtls.secret_key,
        (<HTMLInputElement>document.getElementById('apUrl')).value = this.userServices.appUrl;
      (<any>window).OPENVIDU_SERVER_URL = ovDtls.openvidu_url+'/openvidu';
      // (<any>window).OPENVIDU_SERVER_URL = ovDtls.openvidu_url;
      (<any>window).OPENVIDU_SERVER_SECRET = ovDtls.secret_key;
      (<any>window).vidmed_url = `${this.userServices.appUrl}telecon/`;
      if (this.mdlClsBtn)
        setTimeout(() => { this.mdlClsBtn.click(); }, 1000);
      this.getTenantDetails();
    
    });
  }
  // async  getBase64ImageFromUrl(url) {
  //  // console.log("Downloading image...");
  //   var res = await fetch(url);
  //   var blob = await res.blob();

  //   const result = await new Promise((resolve, reject) => {
  //     var reader = new FileReader();
  //     reader.addEventListener("load", function () {
  //       resolve(reader.result);
  //     }, false);

  //     reader.onerror = () => {
  //       return reject(this);
  //     };
  //     reader.readAsDataURL(blob);
  //   })
  //   this.lgnUrl =result;
  //   console.log('lngURL',this.lgnUrl)
  //   return result
  //   // var res = await fetch(imageUrl);
  //   // var blob = await res.blob();
  
  //   // return new Promise((resolve, reject) => {
  //   //   var reader  = new FileReader();
  //   //   reader.addEventListener("load", function () {
  //   //       resolve(reader.result);
  //   //   }, false);
  
  //   //   reader.onerror = () => {
  //   //     return reject(this);
  //   //   };
  //   //   reader.readAsDataURL(blob);
  //   // })
  // }

  getBase64ImageFromUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }
  
  getTenantDetails() {
    let webURL: string = location.href.includes('localhost') ? environment.applicationURL : location.origin;
    sessionStorage.setItem('isAppURL', '0'), this.tenantData = null,
      this.userServices.getTentantDtls(webURL).toPromise().then((tnt: any) => {
        this.tenantData = tnt.data, 
        sessionStorage.setItem('tenantDtls', JSON.stringify(tnt));
        var tenantDtls: any = JSON.parse(sessionStorage.getItem('tenantDtls')) 
        this.clientName =tenantDtls?.data?.orgnization_name;
        this.tollFree =tenantDtls?.data?.primary_contact_no
        this.organisationImages = this.tenantData?.orgnization_images;
        sessionStorage.setItem('orgnization_images',JSON.stringify(this.organisationImages))
        const orgnization_images = JSON.parse(sessionStorage.getItem('orgnization_images'))
        const loginImage = orgnization_images.filter(f=>f.image_type.id==4)[0];
        this.lgnUrl = loginImage?.image_path?`${this.webURL}/vidmed-orgfiles/ORGANIATION/${this.tenantData?.id }/${loginImage?.image_type?.image_type}/${loginImage?.image_path}`:`${location.origin}${environment.assetsURL}/dhanush${environment.bgLoginNew}`;
     
    
      });
  }

  onTollFree() {
    if (window.orientation == undefined) {
      let selBox = document.createElement('textarea'); selBox.style.position = 'fixed', selBox.style.left = '0', selBox.style.top = '0',
        selBox.style.opacity = '0', selBox.value = this.tollFree, selBox.focus(), document.body.appendChild(selBox), selBox.select(),
        document.execCommand('copy'), document.body.removeChild(selBox), this.toastr.success('Copied to clipboard.!');
    }
    else {
      let link = document.createElement('a');
      link.setAttribute('href', `tel:${this.tollFree}`), link.setAttribute('visibility', 'hidden'), link.setAttribute('display', 'none'),
        link.setAttribute('target', '_blank'), document.body.appendChild(link), link.click()
    }
  }

}