import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { env } from 'process';
// const httpOptions = {
//     headers: new HttpHeaders({ 'Content-Type': 'application/json' })
// };

@Injectable({ providedIn: 'root' })

export class FileService {

    fileServiceAPI: string = '';
    orgfileServiceAPI: string = '';
    TENANT_ID: string = '';
    logTnntId: number = null;

    constructor(private api: ApiService,private authSvc: AuthenticationService) {
        this.fileServiceAPI = environment.fileServiceAPI;
        this.orgfileServiceAPI = environment.orgfileServiceAPI;
        this.TENANT_ID = sessionStorage.getItem('currentTenant')
        authSvc.tnntSbjct.subscribe(s => this.logTnntId = s);
    }
    //File API's
    fileupload(data: any): Observable<any> { return this.api.post(`${environment.orgfileServiceAPI}`, data); }
    uploadFile(data: any): Observable<any> { return this.api.post(`${this.fileServiceAPI}upload?TENANT_ID=${this.authSvc.tenantId == null ? sessionStorage.getItem('currentTenant') : this.authSvc.tenantId}`, data); }
    uploadOrgFile(data: any): Observable<any> { return this.api.post(`${environment.loginAPI}${this.orgfileServiceAPI}upload`, data); }
    upldFile(data: any): Observable<any> { return this.api.post(`${environment.loginAPI}${environment.registrationAPI.replace('jwt/','')}beneficiary/upload?TENANT_ID=${this.authSvc.tenantId == null ? this.TENANT_ID : this.authSvc.tenantId}`, data); }
    // downloadFile(id: number, registrationType: string = 'LAB'): Observable<any> { return this.api.get(`${this.fileServiceAPI}doctor/getdoctorrecordbyid?doctorId=${id}`); }
}
/*
https://vidmedplus.dhanushinfotech.com/file-service/file/upload
request for
==========
{
file :  select File
lab_id:   2,--------->for Update file
id:100,
isActive:  true
screenName:  LAB,PHARMACY,DOCTOR(These are the screenNames need to send either of One)
}

https://vidmedplus.dhanushinfotech.com/file-service/file/download/LAB/21/21_1586695472203_4.pdf
*/