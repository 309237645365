import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { MasterService } from 'src/app/shared/services/master.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/shared/services/user.service';
import { LabService } from 'src/app/shared/axaservices/lab.service';

declare let $: any;

@Component({
  selector: 'app-prescription',
  templateUrl: './prescription.component.html',
  styleUrls: ['./prescription.component.css'],
  providers: [DatePipe]
})

export class PrescriptionComponent implements OnInit {

  @Input() prescriptionData: any;
  @Input() show: any;
  @Input() provDgns: any;
  @Input() member_mobile: any;
  @Input() regNo: any;
  @Input() depDtls: any;
  @Input() lab: string;
  @Input() pharm: string;
  @Input() labDisc: any;
  @Input() phrmDisc: any;
  @Input() isPcChecked: any;
  @Input() drugsData: any;
  @Input() hospitalName: any;
  @Input() referral_hospital: any;
  @Input() procedurevalue: any;
  @Input() investigationData: any = [];
  benData :any;
  // benData = { id: '', first_name: '', middle_name: '', last_name: '', mobile: null, age: null, address: null, gender_id: null, member_mobile: null };
  doc = { first_name: '', last_name: '', qualification: '', superspecialiy: '', mci: null, address: null, pinCode: '', mobile: '', member_mobile: '' };
  pocCc = ''; otherIfany = ''; provDiagnosis = ''; pocVitals = ''; pocHistory = ''; pocPd = ''; pocIn = ''; pocAdvice = ''; pocComment = ''; minDate: Date; medName: ''; medType: '';
  pocDrug = { drug_type: '', drug_name: '', dsg: '', dir: '', ins: '', noofdays: '' };
  qualificationsList = []; superSpecialityDegreeList = []; imgSignurl = ''; noteData = ''; nAD = [];
  // covid data
  covid19 = { symptomsdata: '', co_morbiditiesdata: '', precautionsdata: '', pointOfCaredata: '', chemistrydata: '', hematologydata: '', inuenza_pcrdata: '', sars_cov2data: '', urinedata: '', plainFilmsdata: '', ctScandata: '', instructionsdata: '', patienteducationdata: '' };
  trmprt = ''; symptomsProgresseddata = ''; trvlhistry = '';
  pocVitals_exists: string = '';
  pocAdvice_exists: string;
  registered_name: string = '';
  is_self: boolean = true;
  instruction: string = '';
  gendersList: any = [];
  isEnabledPharmaCost: boolean = false;
  isEnabledLabCost: boolean = false;
  clientName = ''; appSrce = ''; bg = ''; presLogo = ''; tollFree = ''; pcRequired: any; orgName: any; isUkt: any
  memData: any; benId: any; mobNum: any; healthidaddress: any = ''; healthidnumber: any = '';
  Flagslist: any;
  shwABHDetails: boolean;
  shwLogos: boolean;
  isEnableCovid19: boolean
  isEnableAdviceAllField: boolean;
  insuranceOPDservicesList: any[];
  isenableLabServicesInPrescription: any;
  enableInsuranceDetailsInPoc: any;
  constructor(public activeModal: NgbActiveModal, private labService: LabService,
    private toastr: ToastrService, private userServ: UserService
    , private formBuilder: FormBuilder
    , private registrationService: RegistrationService
    , private masterService: MasterService
    , config: NgbModalConfig
    , private modalService: NgbModal
    , private authServ: AuthenticationService
    , private datePipe: DatePipe
    , private router: Router) {
    this.getUserPermission()
    this.orgName = sessionStorage.getItem('orgName')
    if (this.orgName == "Uttarakhand Telemedicine") {
      this.isUkt = true
    } else {
      this.isUkt = false
    }
    const familyMemberDetails = (sessionStorage.pocEditFmember || '').replace('null', '');
    // if(Object.keys(sessionStorage).includes('pocEditFmember')){
    // if (sessionStorage.getItem('pocEditFmember') != null && sessionStorage.getItem('pocEditFmember') != undefined && sessionStorage.getItem('pocEditFmember') !== '') {
    if (familyMemberDetails) {
      this.benId = this.registrationService.familyDetails?.id;
      this.memData = JSON.parse(sessionStorage.getItem('pocEditFmember'));
      this.mobNum = this.registrationService.familyDetails?.mobile;
    }
    //  }
    this.healthidaddress = sessionStorage.getItem('health_ida');
    this.healthidnumber = sessionStorage.getItem('health_idn');
  }

  ngOnInit(): void {
    var tenantDtls: any = JSON.parse(sessionStorage.getItem('tenantDtls'));
    this.authServ.appSrceSubject.subscribe(s => {
      this.appSrce = s; // this.tollFree = (s == 'sage' ? `, ${environment.tollFreeSage}` : `, ${environment.tollFreeIND}`),
      const loginImageOBJ = JSON.parse(sessionStorage.getItem('orgnization_images'))
      const pocBgLogo = loginImageOBJ.filter(f => f.image_type.id == 2)[0];
      const pocPresLogo = loginImageOBJ.filter(f => f.image_type.id == 6)[0];
      this.prescriptionData.advice?.is_self ? true : false
      this.bg = pocBgLogo ? `${environment.applicationURL}/vidmed-orgfiles/ORGANIATION/${tenantDtls?.data?.id}/${pocBgLogo.image_type?.image_type}/${pocBgLogo.image_path}` : `${location.origin}${environment.assetsURL}${this.appSrce}${environment.background}`;
      this.presLogo = pocPresLogo ? `${environment.applicationURL}/vidmed-orgfiles/ORGANIATION/${tenantDtls?.data?.id}/${pocPresLogo.image_type?.image_type}/${pocPresLogo.image_path}` : `${location.origin}${environment.assetsURL}${this.appSrce}${environment.logo_prescrption}`;
    }),
      this.authServ.tollFreeSubject.subscribe(s => this.tollFree = `, ${s}`),
      this.authServ.appClntSubject.subscribe(s => this.clientName = s);
    this.drugsData;
    for (let i = 0; i < this.prescriptionData.prescribed_medications.length; i++) {
      this.prescriptionData.prescribed_medications[i].name = this.prescriptionData.prescribed_medications[i].name.toUpperCase();
      //  this.prescriptionData.prescribed_medications[i]['cost'] = '';
      for (let j = 0; j < this.drugsData.length; j++) {
        if (this.drugsData[j].drug_name != undefined) {
          this.drugsData[j].drug_name = this.drugsData[j].drug_name.toUpperCase();
        }
        if (this.prescriptionData.prescribed_medications[i].name == this.drugsData[j].drug_name) {
          this.prescriptionData.prescribed_medications[i].agentStock = this.drugsData[j].stock;
        }
        if (this.prescriptionData.prescribed_medications[i].name != this.drugsData[j].drug_name) {
          this.nAD.push(this.prescriptionData.prescribed_medications[i].name);
          //  +'is not available with the phc pharmacy and need to be purchase from outside pharmacy'
        }
      }
    }
    sessionStorage.setItem('agentstock', JSON.stringify(this.prescriptionData.prescribed_medications));
    //    console.log(this.prescriptionData,'1');
    if (this.prescriptionData)
      this.getAllMaster();
    this.PackagesWithServices(this.investigationData)
    // if (this.investigationData.id = "") {
    //   this.PackagesWithServices;
    // }
  }

  PackagesWithServices(payLoad) {
    // console.log('package list',payLoad);
    if (payLoad.length == 0) {
      // return this.toastr.info('If you want to go further please select prescreption investigation');
      console.log('laboratory Investigation dropdown not selected api not called');      
    } else {
      this.labService.packageWithServices(`LabServices/getlabServicesByPackage`, payLoad).subscribe(res => {
        if (res?.status == 'OK') {
          if (res.data == null || res.data.length == 0) {
            this.toastr.error(res.message);
          }
          else {
            this.insuranceOPDservicesList = res.data;
          }
        }
      },
        err => {
          this.toastr.error(err.message);

        },
        () => { })
    }
  }

  getUserPermission() {
    let orgIdValue = JSON.parse(sessionStorage.getItem('currentTenant'));
    this.userServ.getUserPermissionByOrgId(orgIdValue).subscribe((res: any) => {
      if (res.status == 'OK') {
        this.Flagslist = res.data;
        for (let i = 0; i < res.data.length; i++) {
          // if (res.data[i].feature_id.feature_name == "doctorPocfilter") {
          //   this.pocpatientHistory = res.data[i].patient
          // }
          if (res.data[i].feature_id.feature_name == "hideLinkWithAbha") {
            this.shwABHDetails = res.data[i].patient
          }
          if (res.data[i].feature_id.feature_name == "isEnableLogos") {
            this.shwLogos = res.data[i].patient;

          }
          if (res.data[i].feature_id.feature_name == "isEnableCovid19") {
            this.isEnableCovid19 = res.data[i].patient
          }
          if (res.data[i].feature_id.feature_name == "isEnableAdviceAllField") {
            this.isEnableAdviceAllField = res.data[i].patient
          }
          if (res.data[i].feature_id.feature_name == "isenableLabServicesInPrescription") {
            this.isenableLabServicesInPrescription = res.data[i].patient
          }
          if (res.data[i].feature_id.feature_name == "enableInsuranceDetailsInPoc") {
            this.enableInsuranceDetailsInPoc = res.data[i].patient;
          }

          //isEnableCovid19
          // if (res.data[i].feature_id.feature_name == "hideOtherDrugLabelinPOC") {
          //   if (res.data[i].patient) {
          //     this.hideOtherDrugLabel = true;
          //   } else {
          //     this.hideOtherDrugLabel = false;
          //   }  
        }
      }
    });
  }
  View() {
    //Doctor Details
    let qualificationName = this.qualificationsList.filter(f => f.id == this.prescriptionData.doctor_id.qualification_id)[0]?.qualification;
    let superDegree = this.superSpecialityDegreeList.filter(m => m.id == this.prescriptionData.doctor_id.super_qualification_id)[0]?.qualification;
    let [adrs, pinCode] = ['', ''];
    if (this.prescriptionData.doctor_id.doctor_address) {
      let firstAddress = this.prescriptionData.doctor_id.doctor_address.find(f => f.display_on_prescription == true);
      if (firstAddress)
        [adrs, pinCode] = [firstAddress.address, firstAddress.pin_code];
    }
    // let adrs = this.prescriptionData.doctor_id.doctor_address ? this.prescriptionData.doctor_id.doctor_address.filter(f => f.display_on_prescription == true)[0]?.address : '';
    // let pinCode = this.prescriptionData.doctor_id.doctor_address ? this.prescriptionData.doctor_id.doctor_address.filter(f => f.display_on_prescription == true)[0]?.address : '';
    this.doc = {
      first_name: this.prescriptionData.doctor_id.first_name, last_name: this.prescriptionData.doctor_id.last_name, qualification: qualificationName,
      superspecialiy: superDegree, mci: this.prescriptionData.doctor_id.mci_number, address: adrs, pinCode: pinCode, mobile: this.prescriptionData.doctor_id.mobile, member_mobile: this.prescriptionData.doctor_id
    };

    let path = this.prescriptionData.doctor_id.doctor_documents?.filter(f => f.file_type_id == 8)[0]?.document_path ?? '';

    if (this.prescriptionData.doctor_id.id != undefined)
      this.imgSignurl = `${this.registrationService.viewFilePath}DOCTOR/${this.prescriptionData.doctor_id.id}/DigitalSignature/${path}`;

    // Ben Data
    this.is_self = this.prescriptionData.advice?.is_self ? true : false,
      this.benData = this.prescriptionData.beneficiary_id;

    if (sessionStorage.getItem('pocEditFmember') == '') {
      this.mobNum = this.benData.mobile;
    }

    if (this.is_self) {
      this.mobNum = this.benData.mobile;
    } else {
      this.mobNum = this.registrationService.familyDetails.mobile;
    }

    if (!this.is_self) {
      this.registered_name = this.benData.first_name.toUpperCase() + ' ' + this.benData.last_name.toUpperCase();
      if (this.registrationService.familyDetails && this.registrationService.familyDetails !== undefined) {
        // this.registered_name = this.registrationService.familyDetails.first_name.toUpperCase() + ' ' + this.registrationService.familyDetails.last_name.toUpperCase();
        // this.registered_name = this.registrationService.familyDetails.member_name.toUpperCase() ;

      }
      this.prescriptionData.advice.family_member.id = this.is_self ? this.benData.id : '';
      this.prescriptionData.advice.family_member.mobile = this.benData.mobile;
      this.prescriptionData.advice.family_member.member_mobile = this.benData.member_mobile;
      this.benData = this.prescriptionData.advice.family_member;
    }
    // below line commented for instruction show on preview prescription 18-04-2023
    // if (this.prescriptionData.prescribed_medications != undefined)
    //   this.instruction = this.prescriptionData.prescribed_medications[0]?.instruction ?? '';
    if (this.prescriptionData.instructions != undefined && this.prescriptionData.instructions != null)
      this.instruction = this.prescriptionData.instructions ?? '';


    this.pocCc = '', this.otherIfany = '', this.pocVitals = '', this.pocHistory = '', this.pocPd = '', this.pocIn = '', this.pocAdvice = '';
    let f = this.prescriptionData, cc = '', pD = '';
    if (f.diagnosis.provisionalDiagnosis?.length > 0)
      f.diagnosis.provisionalDiagnosis.forEach(e => {
        if (e.diagnosis_name !== 'Other')
          pD += pD == '' ? e.diagnosis_name : `,${e.diagnosis_name}`;
      });
    this.provDgns = pD;
    // (this.provDgns == '' || this.provDgns == null || this.provDgns == undefined) ? pD : this.provDgns;
    this.provDiagnosis = (pD.length > 0 ? (',' + f.diagnosis.othersifany) : f.diagnosis.othersifany);
    if (f.cc_brief_history.chiefComplaints.length != 0)
      f.cc_brief_history.chiefComplaints.forEach((e, i) => {
        if (e.chief_complaint !== 'Other')
          cc = (cc.length == 0) ? e.chief_complaint : (cc + ', ' + e.chief_complaint);
      });
    this.pocCc = cc + (cc.length > 0 ? (',' + f.cc_brief_history.othersifany) : f.cc_brief_history.othersifany) + (f.cc_brief_history.presentIllness != '' ? (' / ' + f.cc_brief_history.presentIllness) : '');
    // this.otherIfany = (f.cc_brief_history.othersifany != '' ? (',' + f.cc_brief_history.othersifany) : '');
    // this.otherIfany = (cc.length > 0 ? (',' + f.cc_brief_history.othersifany) : f.cc_brief_history.othersifany);
    this.pocVitals_exists = (f.vital_history.temperature != '' ? (('Temperature: ') + f.vital_history.temperature) : '') + (f.vital_history.pulse != '' ? ('; ' + ('Pulse: ') + f.vital_history.pulse) : '')
      + (f.vital_history.systolic != '' ? ('; ' + ('Systolic: ') + f.vital_history.systolic) : '') + (f.vital_history.diastolic != '' ? ('; ' + ('Diastolic: ') + f.vital_history.diastolic) : '');

    this.pocVitals = `<table style="border:0px">
    <tr>
      <th>Temperature</th>
      <th>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;</th>
      <td>${(f.vital_history.temperature) ? (f.vital_history.temperature + '&deg;F') : ''} </td>
    </tr>
    <tr>
      <th>Sugar(mg/dl)</th>
      <th>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;</th>
      <td>${f.vital_history.diabetic_value ? f.vital_history.diabetic_value : ''}</td>
    </tr>
    <tr>
      <th>Systolic</th>
      <th>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;</th>
      <td>${(f.vital_history.systolic) ? (f.vital_history.systolic + ' mmHg') : ''}</td>
    </tr>
    <tr>
      <th>Diastolic</th>
      <th>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;</th>
      <td>${(f.vital_history.diastolic) ? (f.vital_history.diastolic + ' mmHg') : ''}</td>
    </tr>
    <tr>
      <th>Pulse</th>
      <th>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;</th>
      <td>${(f.vital_history.pulse) ? (f.vital_history.pulse + ' bpm') : ''}</td>
    </tr>
    <tr>
      <th>SPO2%</th>
      <th>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;</th>
      <td>${f.vital_history.oxigen_count ? f.vital_history.oxigen_count : ''}</td>
    </tr>
    <tr>
      <th>Height</th>
      <th>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;</th>
      <td>${f.vital_history.height ? (f.vital_history.height + ' cm') : ''}</td>
    </tr>
    <tr>
      <th>Weight</th>
      <th>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;</th>
      <td>${f.vital_history.weight ? (f.vital_history.weight + ' kg') : ''}</td>
    </tr>
  </table>
  `;
    /*
      <tr>
      <th>Hemoglobin</th>
      <th>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;</th>
      <td>${f.vital_history.hemoglobin ? f.vital_history.hemoglobin : ''}</td>
    </tr>
    <tr>
      <th>RBS</th>
      <th>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;</th>
      <td>${f.vital_history.rbsk ? f.vital_history.rbsk : ''}</td>
    </tr>
    */
    this.pocHistory = (f.other_history.history != '' ? (', ' + f.other_history.history) : '') + (f.allergy.allergies != '' ? (', ' + f.allergy.allergies) : '');



    if (!this.isEnableAdviceAllField) {
      this.pocAdvice_exists = (f.advice.restExercise != '' ? (('Advice ') + f.advice.restExercise) : '');
      this.pocAdvice = `<table style="border:0px">
    <tr>
      <th></th>
      <td>${(f.advice.restExercise) ? (f.advice.restExercise) : ''} </td>
    </tr>
  
  </table>`;
    } else {
      this.pocAdvice_exists = (f.advice.restExercise != '' ? (('Rest/Exercise: ') + f.advice.restExercise) : '') + (f.advice.diet != '' ? ('; ' + ('Diet: ') + f.advice.diet) : '') +
        (f.advice.followAdvice != '' ? ('; ' + ('Follow Advice: ') + f.advice.followAdvice) : '');

      this.pocAdvice = `<table style="border:0px">
    <tr>
      <th>Rest/Exercise</th>
      <th>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;</th>
      <td>${(f.advice.restExercise) ? (f.advice.restExercise) : ''} </td>
    </tr>
    <tr>
      <th>Diet</th>
      <th>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;</th>
      <td>${(f.advice.diet) ? f.advice.diet : ''}</td>
    </tr>
    <tr>
      <th>Follow Advice</th>
      <th>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;</th>
      <td>${(f.advice.followAdvice) ? f.advice.followAdvice : ''}</td>
    </tr>     
    <tr>
      <th>Comments</th>
      <th>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;</th>
      <td>${(f.advice.doctorComment) ? f.advice.doctorComment : ''}</td>
    </tr>    
  </table>`;
    }



    this.pocComment = (f.advice.referral != '' ? (('Referral: ') + f.advice.referral) : '') + (f.advice.doctorComment != '' ? ('; ' + ('Comments: ') + f.advice.doctorComment) : '');

    // this.pocVitals = this.pocVitals.replace(/^,/, '').replace(/^;/, '');
    this.pocHistory = this.pocHistory.replace(/^,/, '');
    this.pocAdvice = this.pocAdvice.replace(/^,/, '').replace(/^;/, '');
    this.pocComment = this.pocComment.replace(/^,/, '').replace(/^;/, '');
    this.trmprt = this.prescriptionData.covid19.temperature;
    this.symptomsProgresseddata = this.prescriptionData.covid19.symptomsProgressed;
    this.trvlhistry = this.prescriptionData.covid19.travelHistory;
    this.covid19.symptomsdata = this.prescriptionData.covid19.symptoms ? this.getCovidFormValues(this.prescriptionData.covid19.symptoms) : '';
    this.covid19.co_morbiditiesdata = this.prescriptionData.covid19.co_morbidities ? this.getCovidFormValues(this.prescriptionData.covid19.co_morbidities) : '';
    this.covid19.precautionsdata = this.prescriptionData.covid19.precautions ? this.getCovidFormValues(this.prescriptionData.covid19.precautions) : '';
    this.covid19.pointOfCaredata = this.prescriptionData.covid19.pointOfCare ? this.getCovidFormValues(this.prescriptionData.covid19.pointOfCare) : '';
    this.covid19.chemistrydata = this.prescriptionData.covid19.chemistry ? this.getCovidFormValues(this.prescriptionData.covid19.chemistry) : '';
    this.covid19.hematologydata = this.prescriptionData.covid19.hematology ? this.getCovidFormValues(this.prescriptionData.covid19.hematology) : '';
    this.covid19.inuenza_pcrdata = this.prescriptionData.covid19.influenza_pcr ? this.getCovidFormValues(this.prescriptionData.covid19.influenza_pcr) : '';
    this.covid19.sars_cov2data = this.prescriptionData.covid19.sars_cov2 ? this.getCovidFormValues(this.prescriptionData.covid19.sars_cov2) : '';
    this.covid19.urinedata = this.prescriptionData.covid19.urine ? this.getCovidFormValues(this.prescriptionData.covid19.urine) : '';
    this.covid19.plainFilmsdata = this.prescriptionData.covid19.plainFilms ? this.getCovidFormValues(this.prescriptionData.covid19.plainFilms) : '';
    this.covid19.ctScandata = this.prescriptionData.covid19.ctScan ? this.getCovidFormValues(this.prescriptionData.covid19.ctScan) : '';
    this.covid19.instructionsdata = this.prescriptionData.covid19.instructions ? this.getCovidFormValues(this.prescriptionData.covid19.instructions) : '';
    this.covid19.patienteducationdata = this.prescriptionData.covid19.patienteducation ? this.getCovidFormValues(this.prescriptionData.covid19.patienteducation) : '';

  }

  isCovidExists() {
    return Object.keys(this.prescriptionData.covid19).length > 0
  }

  onSubmit() {
    if (sessionStorage.getItem('tempVldtn')) {
      if (sessionStorage.getItem('tempVldtn') == '1')
        this.toastr.error('Please enter Valid Decimal format. Eg.: 98.7 or 100.2');
      else
        this.activeModal.close('SubmitEvent');
    }
    else
      this.activeModal.close('SubmitEvent');
  }

  getAllMaster() {
    const qualifications = this.masterService.getAll('getallqualifications');
    const superSpecialityDegree = this.masterService.getAll('getallsuperspecialityqualifications');
    const genders = this.masterService.getAll('getallgenders');

    forkJoin([qualifications, superSpecialityDegree, genders]).subscribe({
      next: res => {
        res.forEach((list, ind) => {
          if (list.status === 'OK') {
            if (list.data == null || list.data.length == 0)
              this.toastr.info('No records available', 'Prescription');
            else {
              const assignList = {
                '0': () => this.qualificationsList = list.data,
                '1': () => this.superSpecialityDegreeList = list.data,
                '2': () => this.gendersList = list.data,
              }
              assignList[ind]();
            }
          }
        });
      },
      error: err => console.error('something wrong occurred: ', err),
      complete: () => {
        this.View();
      },
    });
  }

  getGender(id: string): string {
    let result = this.gendersList.find(c => c.id == id);
    return (result) ? result.gender_name : '';
  }

  getCovidFormValues(data: any) {
    // let symptoms: Array<any> = [];
    return Object.values(data).filter((e: any) => e.length > 0).toString().replace(/,/g, ', ');

  }

}