import { ValidationErrors } from '@angular/forms';
import { Permissions } from 'src/app/shared/models/permissions.enum';
import { ScreenNames } from 'src/app/shared/models/screen-names.enum';
import { ModuleNames } from 'src/app/shared/models/module-names.enum';
import * as CryptoJS from 'crypto-js';  

declare let $: any;


export enum Avail {
  lclUrl = 'https://vmedprodev.dhanushinfotech.com/',
// lclUrl = 'https://vmedproqa.dhanushinfotech.com/',
  fileUrl = 'file-service/file/view/',
  vmedFileUrl = '/vidmedfiles/',
  vmedFileUrlNew='vidmedfiles/',
  DrTxt = 'DOCTOR/',
  DrFTId = 8,
  VstFilesTxt = 'VISITFILES/',
  VstFleFTId = 6,
  BnfcryTxt = 'BENEFICIARY/',
  BnfcryFTId = 12,
  MemDocsTxt = 'MEMBERDOCUMENTS/',
  InvcFTId = 14,
  MnlPres = 'ManualPrescription',
  dfltHomeUserImg = '../../../assets/images/benIcon.png',
  HiQlfcnId = 9,
  EGCFTId = 17,
  StethoFTId = 18,
  DrImgFTId = 5,
  BioDataId,
  VidRcrdId = 10,
  PanCrdId = 13,
  MnlPresFTId = 15,
  RegFTId = 7
}   


export class Common {

    static loadDataTable(table_data: any, table_name: string) {
        $('.dataTable').DataTable().destroy();
        $('.dataTable').DataTable({ destroy: true, searching: false });
        // if (table_data.length > 0) {
        // }
            const clear =  setTimeout(() => {
            $(table_name).DataTable();
        },1500);
        //clearTimeout(clear);
    }

    static setFocus(elementName: string) {
        let el: any = (<HTMLTextAreaElement>(document.getElementById(elementName)));
        el.value = '';
        el.focus();
    }

    static getFormValidationErrors(formObj: any) {
        Object.keys(formObj.controls).forEach(key => {
            const controlErrors: ValidationErrors = formObj.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });
    }

    static form_Data(uId: string = '', file: any, id: string, sreen_name: string, fileType: string = '', cnsltId: string = '', isFileName: boolean = false) {
        const formData = new FormData(), dt = new Date(), mnth = dt.getMonth().toString(),
            fnlDt = `${dt.getFullYear()}${mnth.length == 1 ? `0${mnth}` : mnth}${dt.getDate()}.jpeg`;
        if (uId != '0')
            formData.append('id', uId);

        if (isFileName)
            formData.append('file', file, fnlDt);
        else
            formData.append('file', file);
        formData.append('fileTypeId', fileType);
        formData.append('reg_id', id);
        formData.append('isActive', 'true');
        formData.append('screenName', sreen_name);
        if (cnsltId != '')
            formData.append('consultation_id', cnsltId);
        return formData;
    }
// new method added for sending userId of phlebo 12-06-2023
static form_Data_new(uId: string = '', file: any, id: string, sreen_name: string, fileType: string = '', userId: string = '', isFileName: boolean = false) {
    const formData = new FormData(), dt = new Date(), mnth = dt.getMonth().toString(),
        fnlDt = `${dt.getFullYear()}${mnth.length == 1 ? `0${mnth}` : mnth}${dt.getDate()}.jpeg`;
    if (uId != '0')
        formData.append('id', uId);

    if (isFileName)
        formData.append('file', file, fnlDt);
    else
        formData.append('file', file);
    formData.append('fileTypeId', fileType);
    formData.append('reg_id', id);
    formData.append('isActive', 'true');
    formData.append('screenName', sreen_name);
    if (userId != '')
        formData.append('user_id', userId);
    return formData;
}
    public static modulePermission(module: ModuleNames, permission: Permissions): boolean {
        const objModule: Array<any> = JSON.parse(atob(sessionStorage.getItem('modules')));
        const result = objModule.find(({ id }) => id == module);
        return result?.canView ?? false;
    }
   
    public static encryptdecrpt(pwd){
        var pwhash = CryptoJS.SHA1(CryptoJS.enc.Utf8.parse("VIDDHANU@!@#"));
        var key = CryptoJS.enc.Hex.parse(pwhash.toString(CryptoJS.enc.Hex).substr(0, 32));
        var text = "";
	var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	// for (var i = 0; i < 50; i++)
	  text += possible.charAt(Math.floor(Math.random() * possible.length));
        var encrypted = CryptoJS.AES.encrypt(pwd, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        
        const ciphertext = encrypted.ciphertext.toString(CryptoJS.enc.Hex);
        return ciphertext;
    }

    public static screenPermission(screen: ScreenNames, permission: Permissions): boolean {
        const objScreen = JSON.parse(atob(sessionStorage.getItem('screen')));
        const result = objScreen.find(({ id }) => id == screen);
        return [result?.can_insert ?? false, result?.can_update ?? false, result?.can_delete ?? false, result?.can_view ?? false][permission];
    }

    static showPDF_File(filePath: string) {
        window.open(filePath,'_blank','left=300,top=50,width=700,height=600,toolbar=1,resizable=0');
        // window.open(filePath,'_blank','fullscreen=yes');
    }
    static showlabpharmaPDF_File(filePath: string) {
    //  window.open(filePath,'_blank');
        window.open(filePath,'_blank',);
    }
}

export function toFormData<T>(formValue: T) {
    const formData = new FormData();
    for (const key of Object.keys(formValue)) {
        const value = formValue[key];
        formData.append(key, value);
    }
    return formData;
}


